import { Event, Maybe, NeededOnly, StopNGo, TokenType } from "@technis/shared";
import gql from "graphql-tag";

export type CreateStopNGoResult = {
  eventById: Maybe<NeededOnly<Event, "id"> & { stopngo: Maybe<StopNGo> }>;
};

export const createStopNGoConfiguration = gql`
  mutation createStopNGo($stopngo: StopNGoCreateInput!) {
    createStopNGo(stopngo: $stopngo) {
      id
      stopngo {
        limit
        lang
        logoUrl
        soundConfig {
          enabled
          timeout
          type
          sounds {
            go
            overlimit
            stop
          }
        }
        customTexts {
          go {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
          stop {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
        }
        showroomConfig {
          enabled
          mediaLibrary {
            url
            type
            uploaded
          }
          slideshows {
            resources {
              mediaKey
              duration
            }
            until
          }
          unit
        }
      }
    }
  }
`;

export type UpdateStopNGoResult = {
  eventById: Maybe<NeededOnly<Event, "id"> & { stopngo: Maybe<StopNGo> }>;
};

export const updateStopNGoConfiguration = gql`
  mutation updateStopNGo($stopngo: StopNGoUpdateInput!) {
    updateStopNGo(stopngo: $stopngo) {
      id
      stopngo {
        limit
        lang
        logoUrl
        soundConfig {
          enabled
          timeout
          type
          sounds {
            go
            overlimit
            stop
          }
        }
        customTexts {
          go {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
          stop {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
        }
        showroomConfig {
          enabled
          mediaLibrary {
            url
            type
            uploaded
          }
          slideshows {
            resources {
              mediaKey
              duration
            }
            until
          }
          unit
        }
      }
    }
  }
`;

export type GetAPITokenTypeResult = {
  getAPITokenType: Maybe<TokenType>;
};

export const getAPITokenType = gql`
  query getAPITokenType {
    getAPITokenType
  }
`;
