import SelectANTD, { SelectProps } from "antd/lib/select";
import React, { FunctionComponent } from "react";
import { i18n } from "../../../lang/i18n";

import { FormErrorsProps } from "./FormErrors";
import { FormItem, LabelSharedProps } from "./FormItem";
import classNames from "classnames";

const Option = SelectANTD.Option;

type Props = SelectProps<any> &
  LabelSharedProps &
  FormErrorsProps & {
    documentId?: string;
    children?: React.ReactNode;
  };

export type OptionProps = { value: string; title?: string; i18n?: string };

export const renderOption: FunctionComponent<OptionProps> = (props: OptionProps) => (
  <Option key={String(props.value)} value={props.value}>
    {props.i18n ? i18n.t(props.i18n, { count: 0 }) : props.title}
  </Option>
);

export const FormSelect: FunctionComponent<Props> = (props: Props) => {
  const { label, className = "", itemClassName, documentId, children, errors, value, ...otherProps } = props;

  return (
    <FormItem label={label} itemClassName={itemClassName} errors={errors}>
      <SelectANTD
        className={classNames("form-select", className)}
        getPopupContainer={documentId ? () => document.getElementById(documentId) as HTMLElement : undefined}
        {...otherProps}
        value={value === null ? undefined : value} // Hack to show placeholder when the value is null
      >
        {children}
      </SelectANTD>
    </FormItem>
  );
};

export const SelectOption = SelectANTD.Option;
