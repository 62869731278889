import React, { FunctionComponent } from "react";
import { useMounted } from "../../hooks";
import { CSSTransition } from "react-transition-group";
import { Textfit } from "react-textfit";
import { formatMessageWithTags, isColorLight, TagsParams } from "../../utils/stopngo";
import { Colors } from "../../utils/colors";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { Count, StopNGoCustomText, calculateWaitingTime } from "@technis/shared";
import logoTechnisLight from "../../../public/logo-technis-light.svg";
import logoTechnisDark from "../../../public/logo-technis-dark.svg";

import { EnterArrow } from "../common/svg/EnterArrow";
import { StopHand } from "../common/svg/StopHand";
import classNames from "classnames";

type Props = {
  hidden: boolean;
  isBiggerThanLimit: boolean;
  numOfAllowedEntries: number;
  limit: number;
  eventName: string;
  customText: StopNGoCustomText;
  lang: string;
  logoUrl: string;
  hasTechnisLogo: boolean;
  count: Pick<Count, "in" | "out">;
  isSplitStopNGo?: boolean;
  flowRate?: number;
};

export const StopNGoOfficialMessage: FunctionComponent<Props> = props => {
  const { isBiggerThanLimit, numOfAllowedEntries, limit, eventName, customText, lang, logoUrl, hidden, hasTechnisLogo, isSplitStopNGo, count, flowRate } = props;
  const {
    title,
    message,
    iconUrl,
    styles: { titleColor, messageColor, backgroundColors },
  } = customText;

  const statusColor = isBiggerThanLimit ? Colors.RED : Colors.GREEN;

  const backgroundColor0 = backgroundColors[0] || statusColor;
  const backgroundColor1 = backgroundColors[1] || (backgroundColors[0] ? backgroundColor0 : Colors.DARK_BLUE);
  const finalBackgroundColors = [backgroundColor0, backgroundColor1];
  const isBackgroundLight = isColorLight(backgroundColor1);

  const defaultTextTranslation = isBiggerThanLimit ? translation.stop : translation.go;
  const finalTitle = (title || i18n.t(defaultTextTranslation.title)).toUpperCase();
  const finalTitleColor = titleColor || statusColor;
  const finalMessageColor = messageColor || (isBackgroundLight ? Colors.DARK_BLUE : Colors.WHITE);

  const finalIconUrl = iconUrl || (isBiggerThanLimit ? <StopHand color={finalTitleColor} className="media" /> : <EnterArrow color={finalTitleColor} className="media" />);

  const insideValue = limit - numOfAllowedEntries;
  const waitingTimeInSeconds = flowRate && insideValue ? calculateWaitingTime([insideValue], flowRate)[0] : 0;

  let finalMessage: React.ReactNode = message;
  const formatMessageValues: TagsParams = {
    remaining: { value: numOfAllowedEntries, style: { color: finalTitleColor } },
    inside: { value: insideValue, style: { color: finalTitleColor } },
    eventName: { value: eventName, style: { color: finalTitleColor } },
    limit: { value: limit, style: { color: finalTitleColor } },
    newLine: { value: null },
    waitingTime: { value: waitingTimeInSeconds, style: { color: finalTitleColor } },
    totalCount: { value: (count.in || 0) + (count.out || 0), style: { color: finalTitleColor } },
  };
  if (!finalMessage) {
    const distanceWarning = (
      <>
        {i18n.t(translation.go.warning)} <span className="distance">2m</span>.
      </>
    );
    const defaultMessageTranslation = i18n.t(defaultTextTranslation.message, { count: numOfAllowedEntries });
    finalMessage = isBiggerThanLimit ? (
      defaultMessageTranslation
    ) : lang === "pl" ? (
      <>
        {formatMessageWithTags(defaultMessageTranslation, formatMessageValues)} <br />
        <span className="warning">{distanceWarning}</span>
      </>
    ) : (
      distanceWarning
    );
  } else {
    finalMessage = formatMessageWithTags(finalMessage as string, formatMessageValues);
  }

  return (
    <div className={classNames("fullscreen", { hidden })}>
      <Footer className="invisible" logoUrl={logoUrl} isBackgroundLight={isBackgroundLight} hasTechnisLogo={hasTechnisLogo} isSplitStopNGo={isSplitStopNGo} />
      <GradientBackground backgroundColors={finalBackgroundColors} />
      <div className="main-content">
        <div className="left-part">
          <Icon key={`${isBiggerThanLimit}`} iconUrl={finalIconUrl} offsetIcon={isBiggerThanLimit && !iconUrl} />
        </div>
        <div className="right-part">
          <Textfit className="title" style={{ color: finalTitleColor }} mode="single" min={1} max={2000} forceSingleModeWidth={false}>
            {finalTitle}
          </Textfit>
          <Textfit className="message" style={{ color: finalMessageColor }} mode="multi" forceSingleModeWidth={false}>
            {finalMessage}
          </Textfit>
        </div>
      </div>
      <Footer logoUrl={logoUrl} isBackgroundLight={isBackgroundLight} hasTechnisLogo={hasTechnisLogo} isSplitStopNGo={isSplitStopNGo} />
    </div>
  );
};

type IconProps = {
  iconUrl: string | React.ReactNode;
  offsetIcon?: boolean;
};

const Icon: FunctionComponent<IconProps> = props => {
  const { iconUrl, offsetIcon } = props;
  const mounted = useMounted();
  return (
    <CSSTransition in={mounted} timeout={0} classNames="icon" appear unmountOnExit>
      <div className={classNames("icon", { offset: !!offsetIcon })}>{typeof iconUrl === "string" ? <img src={iconUrl} className="media" /> : iconUrl}</div>
    </CSSTransition>
  );
};

type GradientBackgroundProps = {
  backgroundColors: string[];
};

const GradientBackground: FunctionComponent<GradientBackgroundProps> = props => {
  const { backgroundColors } = props;
  const mounted = useMounted();
  const backgroundStyle: React.CSSProperties = { backgroundImage: `radial-gradient(${backgroundColors[0]}, ${backgroundColors[1]}, ${backgroundColors[1]})` };
  return (
    <CSSTransition in={mounted} timeout={0} classNames="gradient-bg" appear unmountOnExit>
      <div className="gradient-bg" style={backgroundStyle} />
    </CSSTransition>
  );
};

type FooterProps = {
  logoUrl: string;
  hasTechnisLogo: boolean;
  className?: string;
  isBackgroundLight: boolean;
  isSplitStopNGo?: boolean;
};

const Footer: FunctionComponent<FooterProps> = props => {
  const { logoUrl, className, isBackgroundLight, hasTechnisLogo, isSplitStopNGo = false } = props;
  const aloneClassName = logoUrl ? null : "alone";
  return (
    <div className={classNames("footer", className, aloneClassName)}>
      {logoUrl ? <img className="client-logo media" src={logoUrl} /> : null}
      {hasTechnisLogo ? (
        <div className={classNames("powered-by", { alone: !!logoUrl, light: !!isBackgroundLight, split: isSplitStopNGo })}>
          powered by <img src={isBackgroundLight ? logoTechnisDark : logoTechnisLight} />
        </div>
      ) : null}
    </div>
  );
};
