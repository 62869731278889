import React, { FunctionComponent } from "react";

import { i18n } from "../../../lang/i18n";
import classNames from "classnames";

export type FormErrorsProps = {
  errors?: string[];
};

type Props = {
  className?: string;
};

export const FormErrors: FunctionComponent<FormErrorsProps & Props> = ({ errors = [], className = "" }) =>
  errors.length ? (
    <div className={classNames("form-error", className)}>
      {errors.map(error => (
        <div className="error" key={error}>
          {i18n.t(error)}
        </div>
      ))}
    </div>
  ) : null;
