import React from "react";
import classNames from "classnames";

interface Props {
  width?: string | number;
  height?: string | number;
  className?: string;
  viewBox?: string;
  scale?: number;
  style?: React.CSSProperties;
}

/*
// eslint:disable max-line-length
export const Loader = (props: Props) => {
  const { scale = 0.5, ...svgProps } = props;
  return (
    <svg width="200px" height="200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...svgProps}>
      <g transform="translate(50,50)">
        <g transform={`scale(${scale})`}>
          <g transform="translate(-50,-50)">
            <g transform="rotate(55.8538 50 50)">
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="0.75s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
              <path ng-attr-fill="{{config.c1}}" d="M50 50L50 0A50 50 0 0 1 100 50Z" fillOpacity="0.8" fill="#ffffff"></path>
            </g>
            <g transform="rotate(221.89 50 50)">
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
              <path ng-attr-fill="{{config.c2}}" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)" fillOpacity="0.8" fill="#d6d6d6"></path>
            </g>
            <g transform="rotate(27.9269 50 50)">
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.5s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
              <path ng-attr-fill="{{config.c3}}" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)" fillOpacity="0.8" fill="#b96d89"></path>
            </g>
            <g transform="rotate(193.963 50 50)">
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="3s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
              <path ng-attr-fill="{{config.c4}}" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)" fillOpacity="0.8" fill="#a71e56"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
*/

export const Loader = ({ width = 70, className, style }: Props) => {
  const addDiv = (num: number) => <div>{(num + 3) * 10 < width ? addDiv(num + 1) : null}</div>;

  return (
    <div className={classNames("loader", "loader4", className)} style={{ ...style, width: width + "px", height: width + "px" }}>
      {addDiv(0)}
    </div>
  );
};
