import { Maybe, StopNGoShowroomConfigSlideshowMediaTypes } from "@technis/shared";
import Upload, { UploadChangeParam, UploadProps } from "antd/lib/upload/index";
import { UploadFile } from "antd/lib/upload/interface";
import loadImage from "blueimp-load-image";
import React, { FunctionComponent, useContext, useRef } from "react";
import { IoIosClose, IoIosCloudUpload } from "react-icons/io";
import { getBase64, getBase64MediaType } from "../../../utils/image";

import { FormErrorsProps } from "./FormErrors";
import { FormItem, LabelSharedProps } from "./FormItem";
import { Media } from "../Media";
import classNames from "classnames";
import { Message } from "../Message";
import { i18n } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { GradientIcon } from "../GradientIcon";
import { ThemeContext } from "../Theme";
import { getThemeColors } from "../../../utils/colors";

type Props = Omit<UploadProps, "onChange"> &
  LabelSharedProps &
  FormErrorsProps & {
    waitForUploadLabel?: string;
    value?: { data: string; type: StopNGoShowroomConfigSlideshowMediaTypes };
    onChange?: (base64: Base64) => void;
    style?: React.CSSProperties;
  };

export type Base64 = Maybe<string>;

export const FormMediaUpload: FunctionComponent<Props> = (props: Props) => {
  const { waitForUploadLabel, itemClassName, errors, onChange, value, style, label } = props;
  const isOnChangingRef = useRef(false);
  const [theme] = useContext(ThemeContext);
  return (
    <FormItem itemClassName={classNames("form-media-upload", itemClassName)} errors={errors} style={style}>
      <Upload
        listType="picture"
        showUploadList={false}
        onChange={(info: UploadChangeParam<UploadFile>) => {
          if (!isOnChangingRef.current) {
            isOnChangingRef.current = true;
            getBase64(info.file.originFileObj)
              .then(base64 => {
                const type = getBase64MediaType(base64);
                if (type && onChange) {
                  if (type === StopNGoShowroomConfigSlideshowMediaTypes.IMAGE && base64) {
                    // @ts-ignore
                    loadImage(base64, { orientation: true, canvas: true }).then(res => {
                      onChange(res.image.toDataURL());
                    });
                  } else {
                    onChange(base64);
                  }
                } else {
                  Message.error({ text: i18n.t(translation.edit.unsupportedMediaFile) });
                }
              })
              .finally(() => {
                isOnChangingRef.current = false;
              });
          }
        }}
      >
        {value ? (
          <Media src={value.data} type={value.type} />
        ) : (
          <div className="new-upload-container">
            <div className="new-upload">
              <GradientIcon iconComponent={<IoIosCloudUpload className={"icon"} />} gradientColors={getThemeColors(theme).gradients} />
              {label}
              {waitForUploadLabel ? <span className={"icon-subtitle"}>{waitForUploadLabel}</span> : null}
            </div>
          </div>
        )}
      </Upload>
      {value ? (
        <div
          className="delete-wrapper"
          onClick={() => {
            if (onChange) onChange(null);
          }}
        >
          <IoIosClose />
        </div>
      ) : null}
    </FormItem>
  );
};
