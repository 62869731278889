import { StopNGoShowroomConfigSlideshowMediaTypes } from "@technis/shared";
import React, { FunctionComponent, DOMAttributes } from "react";
import ReactPlayer from "react-player";
import classNames from "classnames";

export type MediaProps = Pick<DOMAttributes<HTMLDivElement>, "onClick"> & {
  src: string | null | undefined;
  type: StopNGoShowroomConfigSlideshowMediaTypes | null;
  width?: string;
  height?: string;
  className?: string;
  isBlackBackground?: boolean;
};

export const Media: FunctionComponent<MediaProps> = (props: MediaProps) => {
  const { src, type, width = "100%", height, className, isBlackBackground, onClick } = props;

  const _classNames = classNames("media", className, { "black-background": isBlackBackground });
  if (isBlackBackground) return <div className={_classNames} />;

  if (!src) return null;

  switch (type) {
    case StopNGoShowroomConfigSlideshowMediaTypes.IMAGE:
      return <img className={_classNames} width={width} height={height} src={src} onClick={onClick} />;
    case StopNGoShowroomConfigSlideshowMediaTypes.VIDEO:
      return (
        <ReactPlayer
          url={src}
          playing={false}
          className={_classNames}
          width={width}
          height={height}
          controls={false}
          config={{
            file: {
              attributes: {
                preload: "metadata",
              },
            },
            youtube: {
              playerVars: {
                showinfo: 0,
                modestbranding: 1,
              },
            },
          }}
          onClick={onClick}
        />
      );
  }
  return null;
};

type MediaWithBlurProps = DOMAttributes<HTMLDivElement> &
  MediaProps & {
    containerClassName?: string;
  };

export const MediaWithBlur: FunctionComponent<MediaWithBlurProps> = props => {
  const { src, type, width, height, className, containerClassName, ...divProps } = props;

  return (
    <div className={classNames("media-with-blur", containerClassName)} {...divProps}>
      <Media src={src} type={type} className={className} width={width} height={height} />
      <Media
        src={src}
        type={type}
        className={classNames("background", className)}
        width={width}
        height={height}
        isBlackBackground={type === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO}
      />
    </div>
  );
};
