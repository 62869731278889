import { Count, ID, Maybe } from "@technis/shared";

import { getDatabase, onValue, ref, off } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from ".";
import { firebaseApp } from "..";

const getZoneCountUrl = (periodId: ID, zoneId: ID) => `${process.env.FIREBASE_DATABASE_ROOT}/counts/${periodId}/zone|${zoneId}`;

type CountFirebase = {
  person?: Maybe<Count>;
};

const DISCONNECT_CHECK_INTERVAL = 59999; // Every 59 seconds
// const THROTTLING_FLUX_TIMEOUT = 5000;
// const FLUX_CONTROL_MARGIN = 5;

/*
type FluxControl = {
  enabled: boolean;
  throttleStartedAt: Maybe<number>;
};
*/

const calculateNumOfAllowedEntries = (limit: number, count: Maybe<Count>) => limit - ((count?.in || 0) - (count?.out || 0));

export const useCount = (token: string, periodId: ID, topZoneId: ID, limit: number) => {
  const [count, setCount] = useState<Maybe<Count>>(null);
  // const [fluxControl, setFluxControl] = useState<FluxControl>({ enabled: false, throttleStartedAt: null });

  const isConnectedRef = useRef(false);
  const limitRef = useRef(limit);
  // const canUseFluxControlRef = useRef(false);
  const numOfAllowedEntriesRef = useRef(0);

  useEffect(() => {
    limitRef.current = limit;
    // canUseFluxControlRef.current = limit > FLUX_CONTROL_MARGIN;
    numOfAllowedEntriesRef.current = calculateNumOfAllowedEntries(limit, count);
    // setFluxControl(f => (!canUseFluxControlRef.current && f.enabled ? { ...f, enabled: false } : { ...f }));
  }, [limit]);

  const url = getZoneCountUrl(periodId || 0, topZoneId || 0);
  const db = getDatabase(firebaseApp);
  const zoneRef = ref(db, url);

  // Firebase listeners
  const saveCount = (nextCount: Maybe<Count>) => {
    if (nextCount) {
      const nextNumOfAllowedEntries = calculateNumOfAllowedEntries(limitRef.current, nextCount);
      /*
      if (nextNumOfAllowedEntries > 0 && canUseFluxControlRef.current && nextNumOfAllowedEntries <= FLUX_CONTROL_MARGIN) {
        setFluxControl(f => {
          if (!f.enabled) {
            return { ...f, enabled: true };
          }
          return f;
        });
        // Start throttling
        if (nextNumOfAllowedEntries < numOfAllowedEntriesRef.current) {
          const throttleId = Date.now();
          setFluxControl(f => ({ ...f, throttleStartedAt: throttleId }));
          setTimeout(() => {
            setFluxControl(f => {
              if (f.throttleStartedAt === throttleId) {
                return { ...f, throttleStartedAt: null };
              }
              return f;
            });
          }, THROTTLING_FLUX_TIMEOUT);
        }
      } else {
        setFluxControl(f => {
          if (f.enabled) {
            return { ...f, enabled: false };
          }
          return f;
        });
      }
      */
      numOfAllowedEntriesRef.current = nextNumOfAllowedEntries;
    }
    setCount(nextCount);
  };
  const startListening = () => {
    isConnectedRef.current = true;

    onValue(
      zoneRef,
      snap => {
        const val: Maybe<CountFirebase> = snap.val();
        const nextCount = val?.person;
        saveCount(nextCount);
      },
      (err: Error) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };
  const stopListening = () => {
    off(zoneRef);
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);
  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token, periodId]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return {
    count: count || undefined,
    isBiggerThanLimit: numOfAllowedEntriesRef.current <= 0,
    numOfAllowedEntries: numOfAllowedEntriesRef.current,
  };

  /*
  const isThrottling = fluxControl.throttleStartedAt != null;
  return {
    count,
    isBiggerThanLimit: isThrottling || numOfAllowedEntriesRef.current <= 0,
    numOfAllowedEntries:
      isThrottling || fluxControl.enabled ? 1 : canUseFluxControlRef.current ? numOfAllowedEntriesRef.current - FLUX_CONTROL_MARGIN : numOfAllowedEntriesRef.current,
  };
   */
};
