import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getDatabase, off, onValue, ref } from "firebase/database";
import React, { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { apollo } from "./api/apollo";
import { MainRoutes } from "./components/routing/MainRoutes";
import { persistor, store } from "./redux/store";
import { ErrorSelection, NoServerError } from "./components/common/error/Errors";
import { ThemeContextProvider } from "./components/common/Theme";
import { SegmentTracking } from "./namespaces/segmentTracking";

// eslint:disable
require("antd/dist/antd.less");
require("../styles/index.scss");

const { initClient } = apollo;

const configFirebase = {
  apiKey: process.env.FIREBASE_AUTH_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  storageBucket: process.env.GCLOUD_STORAGE_BUCKET,
};
export const firebaseApp = initializeApp(configFirebase);
export const firebaseAuth = getAuth(firebaseApp);

export const reloadPage = (timeout: number) => {
  setTimeout(() => {
    window.location.reload();
  }, timeout);
};

// Auto refresh at midnight
const now = new Date();
const night = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
const msTillMidnight = night.getTime() - now.getTime();
reloadPage(msTillMidnight);

const RELOAD_PAGE_ON_ERROR_INTERVAL = 5000;

export const TrackingProvider: FunctionComponent<PropsWithChildren<any>> = props => {
  useEffect(() => {
    SegmentTracking.init();
  }, []);

  return props.children;
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const db = getDatabase(firebaseApp);
  const stopNGoReloadRef = ref(db, `environment/triggerStopNGoReload/${process.env.ENV}`);

  const isFirebaseReady = useRef(false);

  useEffect(() => {
    initClient()
      .then(() => setLoading(false))
      .catch((e: Error) => {
        console.log(e);
        setError(e.message);
        setLoading(false);
        reloadPage(RELOAD_PAGE_ON_ERROR_INTERVAL);
      });
    onValue(stopNGoReloadRef, () => {
      if (isFirebaseReady.current) {
        reloadPage(0);
      } else {
        isFirebaseReady.current = true;
      }
    });

    return () => {
      off(stopNGoReloadRef);
    };
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    return <ErrorSelection message={error} DefaultError={NoServerError} errorClassName="error-fullscreen" />;
  }

  return (
    <TrackingProvider>
      <ApolloProvider client={apollo.client}>
        <Provider store={store}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <PersistGate loading={null} persistor={persistor as any}>
            <ThemeContextProvider setRoot>
              <BrowserRouter>
                <Route component={MainRoutes} />
              </BrowserRouter>
            </ThemeContextProvider>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </TrackingProvider>
  );
};

render(<App />, document.getElementById("root"));
