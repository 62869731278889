import { StopNGoSoundType } from "@technis/shared";
import React, { FunctionComponent, useContext } from "react";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { FormInput } from "../common/form/FormInput";
import { FormSelect, renderOption } from "../common/form/FormSelect";
import { OnChangeConfiguration, StopNGoState } from "./Configuration";
import { ConfigurationSectionTitle } from "./ConfigurationSectionTitle";
import { IoIosVolumeHigh, IoIosVolumeOff } from "react-icons/io";
import { Colors, getThemeColors } from "../../utils/colors";
import classNames from "classnames";
import { SquareDiv } from "../common/SquareDiv";
import { ThemeContext } from "../common/Theme";

type Props = {
  configuration: StopNGoState;
  onChange: OnChangeConfiguration;
};

const soundTypes = () => [
  { value: StopNGoSoundType.VOICE, title: i18n.t(translation.common.voice) },
  { value: StopNGoSoundType.BEEP, title: i18n.t(translation.common.beep) },
];

type AudioOptionProps = {
  label: string;
  onChange: (v: boolean) => void;
  enabled: boolean;
  color: string;
  disabled?: boolean;
  useBackgroundImage?: boolean;
};

const AudioOption: FunctionComponent<AudioOptionProps> = ({ label, enabled, onChange, color, disabled, useBackgroundImage = false }) => {
  const Icon = enabled ? IoIosVolumeHigh : IoIosVolumeOff;
  const showEnabledStyle = !disabled && enabled;
  const [theme] = useContext(ThemeContext);
  const { disabledColor, disabledBackground, reverseFont } = getThemeColors(theme);
  return (
    <SquareDiv
      className={classNames("audio-option", { disabled })}
      onClick={() => !disabled && onChange(!enabled)}
      style={{
        opacity: showEnabledStyle ? 1 : 0.5,
        background: showEnabledStyle ? color : disabledBackground,
        color: showEnabledStyle ? reverseFont : disabledColor,
      }}
    >
      <Icon className="icon" />
      <span className="label">{label}</span>
    </SquareDiv>
  );
};

export const ConfigurationSound: FunctionComponent<Props> = props => {
  const {
    configuration: { soundConfig },
    onChange,
  } = props;
  const [theme] = useContext(ThemeContext);

  return (
    <div className="sound">
      <ConfigurationSectionTitle
        title={i18n.t(translation.common.sounds)}
        enabled={soundConfig.enabled}
        onToggle={e => {
          onChange({ "soundConfig.enabled": e });
        }}
      />
      <div className="sound-content two-col" style={!soundConfig.enabled ? { opacity: 0.5 } : undefined}>
        <div className="inputs-spacing">
          <FormSelect
            disabled={!soundConfig.enabled}
            label={i18n.t(translation.common.type)}
            value={soundConfig.type}
            onSelect={e => {
              onChange({ "soundConfig.type": e as string });
            }}
          >
            {soundTypes().map(renderOption)}
          </FormSelect>
          <FormInput
            disabled={!soundConfig.enabled}
            label={i18n.t(translation.common.timeout)}
            value={soundConfig.timeout}
            onChange={e => {
              onChange({ "soundConfig.timeout": e.target.value });
            }}
            placeholder={i18n.t(translation.edit.soundConfig.selectTimeout)}
          />
        </div>
        <div className="audio-options">
          <AudioOption
            disabled={!soundConfig.enabled}
            label={i18n.t(translation.common.go)}
            enabled={soundConfig.sounds.go}
            onChange={e => {
              onChange({ "soundConfig.sounds.go": e });
            }}
            color={getThemeColors(theme).backgroundGradient}
            useBackgroundImage={!!getThemeColors(theme).backgroundGradient}
          />
          <AudioOption
            disabled={!soundConfig.enabled}
            label={i18n.t(translation.common.stop)}
            enabled={soundConfig.sounds.stop}
            onChange={e => {
              onChange({ "soundConfig.sounds.stop": e });
            }}
            color={Colors.LIGHT_RED}
          />
          <AudioOption
            disabled={!soundConfig.enabled}
            label={i18n.t(translation.common.limit)}
            enabled={soundConfig.sounds.overlimit}
            onChange={e => {
              onChange({ "soundConfig.sounds.overlimit": e });
            }}
            color={Colors.ORANGE}
          />
        </div>
      </div>
    </div>
  );
};
