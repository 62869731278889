import { UserLanguage } from "@technis/shared";
import * as I18n from "i18n-js";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";

import en from "./en.json";
import fr from "./fr.json";
import pl from "./pl.json";
import it from "./it.json";
import de from "./de.json";
import es from "./es.json";

export const i18n = I18n;

i18n.locale = "en";
i18n.fallbacks = false;
i18n.translations = { en, fr, pl, de, it, es };

const LANGUAGES = {
  en,
  fr,
  pl,
  de,
  it,
  es
};

export enum SupportedLanguages {
  ENGLISH = "en",
  FRENCH = "fr",
  POLISH = "pl",
}

export function setLanguage(language?: SupportedLanguages | UserLanguage) {
  if (language) {
    const currentLanguage = i18n.translations[language as string] ? language : SupportedLanguages.ENGLISH;
    i18n.locale = currentLanguage;
  }
}

i18n.pluralization["pl"] = (count: number) => {
  const key = count === 1 ? "1" : count >= 2 && count <= 4 ? "2,3,4" : "many";
  return [key];
};

export const setTranslations = (translations: object | null) => {
  if (!isEmpty(translations)) {
    // @ts-ignore
    const mergedTranslations = Object.entries(translations!).reduce((acc, [language, translation]: [string, object]) => {
      const combined = merge(LANGUAGES[language as keyof typeof LANGUAGES] || {}, translation || {});

      return {
        ...acc,
        ...(!isEmpty(combined) && {
          [language]: combined
        })
      };
    }, {});
    // @ts-ignore
    i18n.translations = {
      ...i18n.translations,
      ...mergedTranslations
    };
  }
};
