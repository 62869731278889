import { Indexable, StopNGo as StopNGoType, StopNGoSoundType } from "@technis/shared";
import React, { FunctionComponent, useEffect, useState } from "react";

import { usePrevious } from "../../hooks";
import { SoundModes, SupportedSounds, useMultipleAudio } from "../../hooks/audio";
import { useCount } from "../../hooks/count";
import { SupportedLanguages } from "../../lang/i18n";
import { Sound } from "../../sounds";
import { Loader } from "../common/svg/Loader";
import { StopNGoSlideshow } from "./StopNGoSlideshow";
import { TrackingViewActivityName, TrackingViewActivityTopic } from "../../utils/constants";
import { withTracking } from "../../hoc/withTracking";

const WAIT_FIRST_COUNT_INTERVAL = 2000;

type Props = {
  limit: number;
  periodId: number;
  topZoneId: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  token: string;
  lang: SupportedLanguages;
  stopngo: StopNGoType;
  eventName: string;
  isSplitStopNGo?: boolean;
  isMute?: boolean;
  flowRate?: number;
};

export const StopNGo: FunctionComponent<Props> = withTracking(props => {
  const { limit, token, periodId, topZoneId, loading, setLoading, lang, stopngo, eventName, isSplitStopNGo, isMute, flowRate, trackPageActivity } = props;
  const { soundConfig, customTexts, showroomConfig, logoUrl } = stopngo;
  const hasTechnisLogo = stopngo.hasTechnisLogo ?? true;

  /***
   * Tracking
   */

  trackPageActivity(TrackingViewActivityTopic.slideshow, TrackingViewActivityName.slideshow);

  /***
   * Count
   */

  useEffect(() => {
    setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, WAIT_FIRST_COUNT_INTERVAL);
  }, []);

  const { count = { in: 0, out: 0 }, isBiggerThanLimit, numOfAllowedEntries } = useCount(token, periodId, topZoneId, limit);

  const prevCount = usePrevious(count);
  const prevIsBiggerThanLimit = usePrevious(isBiggerThanLimit);
  const prevNumOfAllowedEntries = usePrevious(numOfAllowedEntries);

  useEffect(() => {
    if (!prevCount && count && loading) {
      setLoading(false);
    }
  }, [count]);

  /***
   * Sound
   */

  const [soundsToLoad, setSoundsToLoad] = useState<Sound[]>([]);
  useEffect(() => {
    const sounds: Indexable<boolean> = soundConfig.sounds || {};
    setSoundsToLoad(soundConfig.enabled ? (Object.keys(sounds).filter(e => sounds[e] === true) as Sound[]) : []);
  }, [soundConfig.sounds, soundConfig.enabled]);

  const player = useMultipleAudio(soundsToLoad, soundConfig.type === StopNGoSoundType.VOICE ? lang : SupportedSounds.BEEP, SoundModes.Queue, soundConfig.timeout, isMute);

  useEffect(() => {
    if (!loading) {
      player.play(isBiggerThanLimit ? "stop" : "go");
    }
  }, [isBiggerThanLimit, loading]);

  useEffect(() => {
    if (!loading && prevIsBiggerThanLimit && prevNumOfAllowedEntries !== undefined && numOfAllowedEntries < prevNumOfAllowedEntries) {
      player.play("overlimit");
    }
  }, [prevIsBiggerThanLimit, numOfAllowedEntries, prevNumOfAllowedEntries]);

  /***
   * Render
   */

  if (loading) {
    return <Loader />;
  }

  const customText = customTexts[isBiggerThanLimit ? "stop" : "go"];

  return (
    <StopNGoSlideshow
      count={count}
      showroomConfig={showroomConfig}
      isBiggerThanLimit={isBiggerThanLimit}
      numOfAllowedEntries={numOfAllowedEntries}
      limit={limit}
      eventName={eventName}
      customText={customText}
      lang={lang}
      logoUrl={logoUrl}
      hasTechnisLogo={hasTechnisLogo}
      isSplitStopNGo={isSplitStopNGo}
      flowRate={flowRate}
    />
  );
});
