import { Indexable, Maybe } from "@technis/shared";
import * as crypto from "crypto";
import dateFormat from "dateformat";
import { ApolloError } from "../api/apollo";
import { i18n } from "../lang/i18n";
import { translation } from "./../lang/translation";
import { MINUTES_IN_HOURS, SECONDS_IN_10_MINUTES, SECONDS_IN_15_MINUTES, SECONDS_IN_20_MINUTES, SECONDS_IN_30_MINUTES, SECONDS_IN_5_MINUTES } from "./date";

export const allApolloErrorsToString = (err: ApolloError) =>
  [...err.networkError, ...err.graphQLErrors]
    .filter(e => e)
    .map(e => e.message)
    .join("\n");

export const clone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const inEnum = <T extends object>(enu: T, value: any) => Object.values(enu).includes(value);

const isString = (value: string | number) => isNaN(Number(value));

export const enumToArray = <T>(e: Indexable<T>) =>
  Object.keys(e)
    .filter(isString)
    .map(key => e[key]);

export const isObject = (value: any) => value !== null && typeof value === "object";

// eslint:disable prefer-for-of
export const omit = <T>(value: T, keys: Array<keyof T>) => {
  if (typeof value === "undefined") {
    return {} as T;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (Array.isArray(value) || !isObject(value) || !Array.isArray(keys)) {
    return value;
  }

  const res = clone(value);
  for (let j = 0; j < keys.length; j += 1) {
    delete res[keys[j]];
  }
  return res;
};

// TODO: Make this <Templated>
export const omitDeep = (value: any, keys: string[] | string) => {
  if (typeof value === "undefined") {
    return {};
  }

  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i += 1) {
      value[i] = omitDeep(value[i], keys);
    }
    return value;
  }

  if (!isObject(value)) {
    return value;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (!Array.isArray(keys)) {
    return value;
  }

  for (let j = 0; j < keys.length; j += 1) {
    delete value[keys[j]]
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      value[key] = omitDeep(value[key], keys);
    }
  }

  return value;
};
// eslint:enable

export const formatNextUrl = (currentUrl: string, nextUrl: string) => {
  if (currentUrl.slice(-1) === "/") {
    return `${currentUrl}${nextUrl}`;
  }
  return `${currentUrl}/${nextUrl}`;
};

export const currentPathMatchUrl = (pathname: string, url: string) => url === pathname || `${url}/` === pathname;

export const minToString = (minutes: number) => {
  const hour = Math.floor(minutes / 60);
  const min = minutes % 60;
  return `${hour < 10 ? "0" + hour : hour}:${min < 10 ? "0" + min : min}`;
};

export const getHumanDate = (d: Date) =>
  d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " + +d.getHours() + ":" + (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();

export const addOpacityToHex = (hex: string, opacity: number) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity === null ? 1 : opacity})` : null;
};

export const createUID = (length = 30) =>
  crypto
    .randomBytes(Math.ceil(length / 2))
    .toString("hex")
    .slice(0, length);

export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const numberize = (value: string) => {
  if (value[value.length - 1] === "-" && value.length > 1) {
    if (value[0] === "-") {
      return parseInt(value.slice(1));
    }
    return parseInt(`-${value}`);
  }
  return Number(value.replace(/[^0-9.\-]+/g, ""));
};
export const onClickPreventPropagation = (callback: () => void) => (event: { stopPropagation: () => void }) => {
  event.stopPropagation();
  return callback && callback();
};

export const mutationInputValue = <T>(oldValue: T, newValue: T) => {
  if (oldValue === newValue || (!oldValue && !newValue)) return undefined;
  if (oldValue && !newValue) {
    // @ts-ignore
    if (typeof oldValue === "number" && newValue === 0) return newValue;
    return null;
  }
  return newValue;
};
// eslint:disable no-any
export const removeUndefined = (object: any) => {
  if (object) {
    Object.keys(object).forEach(key => {
      if (object[key] === undefined) {
        delete object[key];
      } else if (typeof object[key] === "object") {
        object[key] = removeUndefined(object[key]);
      }
    });
  }
  return object;
};

export const promiseLog = <T>(res: T) => {
  console.log(res);
  return res;
};

export const formatNumber = (num: number | string) => `${num}`.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");

export const formatChartTime = (unixTime: number) => {
  const hourAndMin = dateFormat(new Date(unixTime), "HHMM").toString();
  const hours = hourAndMin.charAt(0) !== "0" ? hourAndMin.substr(0, 2) : hourAndMin.substr(1, 1);
  return `${hours}h${hourAndMin.substr(2)}`;
};

export const formatWaitingTime = (waitingTime: number) => {
  if (waitingTime < SECONDS_IN_5_MINUTES) {
    return i18n.t(translation.waitingTime.lessThanFiveMinutes);
  } else if (waitingTime < SECONDS_IN_10_MINUTES) {
    return i18n.t(translation.waitingTime.fromToMinutes, { from: 5, to: 10 });
  } else if (waitingTime < SECONDS_IN_15_MINUTES) {
    return i18n.t(translation.waitingTime.fromToMinutes, { from: 10, to: 15 });
  } else if (waitingTime < SECONDS_IN_20_MINUTES) {
    return i18n.t(translation.waitingTime.fromToMinutes, { from: 15, to: 20 });
  } else if (waitingTime < SECONDS_IN_30_MINUTES) {
    return i18n.t(translation.waitingTime.fromToMinutes, { from: 20, to: 30 });
  }

  return i18n.t(translation.waitingTime.moreThanThirtyMinutes);
};

export const filterDropdownObject = (id: number, text: string, itemClassName?: string, separatorClassName?: string, url?: string, marginLeft?: number, hideLine?: boolean) => ({
  id,
  text,
  itemClassName,
  separatorClassName,
  url,
  marginLeft: `${(marginLeft || 0) * 20}px`,
  hideLine,
});

export const isPastEvent = (endAt?: Maybe<number>) => !!(endAt && endAt < Date.now());
export const isFutureEvent = (startAt: number) => startAt > Date.now();
export const isLiveEvent = (startAt: number, endAt: Maybe<number>) => !isFutureEvent(startAt) && !isPastEvent(endAt);

export const FIELD_VALIDATION_DELAY = 500;

export const debounce = (callback: () => void, immediate = false) => {
  let timeout: NodeJS.Timeout | undefined;

  return (...args: any[]) => {
    const later = () => {
      timeout = undefined;
      if (!immediate) {
        callback.apply(args);
      }
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, FIELD_VALIDATION_DELAY);
    if (callNow) {
      callback.apply(args);
    }
  };
};

// Object auto typed keys
declare global {
  interface PromiseConstructor {
    sequential<T>(arr: (() => Promise<T>)[]): Promise<T[]>;
  }

  interface ObjectConstructor {
    typedKeys<T>(o: T): (keyof T)[];
  }

  interface Date {
    setDayMinute(min: Maybe<number>): number;

    getDayMinute(): number;
  }

  interface Array<T> {
    sum(): number;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Object.typedKeys = Object.keys as any;

Promise.sequential = <T>(arr: (() => Promise<T>)[]) => {
  const res: T[] = [];
  return arr
    .reduce(
      (seq: Promise<T | null>, next, i) =>
        seq
          .then(r => {
            if (r) {
              res.push(r);
            }
            return i === arr.length - 1 ? res : r;
          })
          .then(next),
      Promise.resolve(null),
    )
    .then(() => res);
};

Array.prototype.sum = function() {
  return this.reduce((a: number, b: number) => a + b, 0);
};

Date.prototype.setDayMinute = function(min) {
  if (!min) {
    return this.getTime();
  }
  const H0 = new Date(this).setHours(0, 0);
  return this.setTime(H0 + min * 60000);
};

Date.prototype.getDayMinute = function() {
  return this.getHours() * 60 + this.getMinutes();
};

export const REAL_TIME_LOST_THRESHOLD = 15;
export const DEFAULT_PAGINATION_SIZE = 10;
export const THROTTLE_TIME = 200;

const setPath = (entity: any, [key, ...next]: string[], value: any): any => {
  if (isNaN(Number(key))) {
    return next.length === 0 ? { ...entity, [key]: value } : { ...entity, [key]: setPath(entity[key], next, value) };
  }
  if (Array.isArray(entity)) {
    return next.length === 0
      ? [...entity.slice(0, Number(key)), value, ...entity.slice(Number(key) + 1, entity.length)]
      : [...entity.slice(0, Number(key)), setPath(entity[Number(key)], next, value), ...entity.slice(Number(key) + 1, entity.length)];
  }
  return [value];
};

export const set = <T, O>(obj: O, path: string, value: T): O => setPath(obj, path.split("."), value);

const getPath = (entity: any, [key, ...next]: string[]): any => (next.length === 0 ? entity[key] : getPath(entity[key], next));

export const get = <T = any>(obj: any, path: string): T => getPath(obj, path.split("."));

export const gqlValue = (value: Maybe<string | number | boolean | Array<any> | Indexable<any>>) => {
  switch (typeof value) {
    case "string":
      if (value === "") return null;
      if (!isNaN(Number(value))) return Number(value);
      return value;
    default:
      return value;
  }
};

export const diff = <U extends Indexable<any>, T extends Indexable<any>>(objFrom: T, objTo: U): Partial<U & T> => {
  let res: any = {};
  if (!objFrom) {
    // @ts-ignore
    return objTo;
  }
  if (!objTo) {
    // @ts-ignore
    return objFrom || res;
  }
  Object.entries(objFrom).map(([key, value]) => {
    if (Array.isArray(value) && Array.isArray(objTo[key])) {
      if (JSON.stringify(value) !== JSON.stringify(objTo[key])) {
        res[key] = objTo[key];
      }
    } else if (typeof value === "object" && typeof objTo[key] === "object") {
      const differences = diff(value, objTo[key]);
      if (differences && Object.entries(differences).length) {
        res = { ...res, [key]: differences };
      }
    } else if (objTo[key] != null && value !== objTo[key]) {
      res[key] = objTo[key];
    } else if ((objTo[key] == null && value != null) || (value == null && !objTo.hasOwnProperty(key))) {
      res[key] = null;
    }
  });
  Object.entries(objTo).map(([key, value]) => {
    if (!objFrom.hasOwnProperty(key)) {
      res[key] = value;
    }
  });
  return res;
};

export function toHoursAndMinutes(totalMinutes: number) {
  const minutes = totalMinutes % MINUTES_IN_HOURS;
  const hours = Math.floor(totalMinutes / MINUTES_IN_HOURS);

  return `${addPaddingToDigits(hours, 2, "0")}h${addPaddingToDigits(minutes, 2, "0")}`;
}

function addPaddingToDigits(number: number, maxLength: number, fillString: string) {
  return number.toString().padStart(maxLength, fillString);
}
