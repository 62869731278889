import React from "react";
import { formatNumber, formatWaitingTime } from "./utils";

const SEPARATOR = "{{{}}}";

type Tags = {
  [key: string]: {
    tag: string;
    tagRegex: RegExp;
    format?: (v: string | number | null) => React.ReactNode;
  };
};

const TAGS: Tags = {
  remaining: {
    tag: "[remaining]",
    tagRegex: /\[remaining\]/g,
    format: (v: string | number | null) => (v != null ? formatNumber(v) : null),
  },
  inside: {
    tag: "[inside]",
    tagRegex: /\[inside\]/g,
    format: (v: string | number | null) => (v != null ? formatNumber(v) : null),
  },
  eventName: {
    tag: "[eventName]",
    tagRegex: /\[eventName\]/g,
  },
  limit: {
    tag: "[limit]",
    tagRegex: /\[limit\]/g,
    format: (v: string | number | null) => (v != null ? formatNumber(v) : null),
  },
  totalCount: {
    tag: "[totalCount]",
    tagRegex: /\[totalCount\]/g,
    format: (v: string | number | null) => (v != null ? formatNumber(v) : null),
  },
  newLine: {
    tag: "[newLine]",
    tagRegex: /\[newLine\]/g,
    format: () => <br />,
  },
  waitingTime: {
    tag: "[waitingTime]",
    tagRegex: /\[waitingTime\]/g,
    format: (v: string | number | null) => (v != null ? formatWaitingTime(Number(v)) : null),
  },
};

const formatTag = (tag: string) => `${SEPARATOR}${tag}${SEPARATOR}`;

export type TagsParams = { [key in keyof typeof TAGS]: { value: string | number | null; style?: React.CSSProperties } };

export const formatMessageWithTags = (message: string, params: TagsParams) => {
  let formattedMessage = message;
  Object.values(TAGS).forEach(t => {
    formattedMessage = formattedMessage.replace(t.tagRegex, formatTag(t.tag));
  });
  const splittedMessage = formattedMessage.split(SEPARATOR);
  return (
    <>
      {splittedMessage.map((str, i) => {
        if (i % 2 === 0) {
          return `${str}`;
        }
        const tag = str.substr(0, str.length - 1).substr(1) as keyof typeof TAGS;
        const config = TAGS[tag];
        const param = params[tag];
        if (!config || !param) {
          return null;
        }
        const { value, style } = param;
        const { format } = config;
        const formattedValue = format ? format(value) : value;
        return (
          <span key={`${tag}${i}`} style={{ ...style, fontWeight: "bold" }}>
            {formattedValue}
          </span>
        );
      })}
    </>
  );
};

export const isColorLight = (hexColor: string) => {
  let color = hexColor.substring(1);
  if (color.length === 3) {
    color += color;
  }
  const rgb = parseInt(color, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155;
};

export const getMinutesFromBeginningDay = (date = Date.now()) => {
  const d = new Date(date);
  return d.getHours() * 60 + d.getMinutes();
};
