import { Event, ID, Maybe, NeededOnly, StopNGo, StopNGoToken, Zone } from "@technis/shared";
import gql from "graphql-tag";

/***
 * Queries
 */

export type StopNGoTokenDataResult = {
  stopngoTokenData: Maybe<
    NeededOnly<Event, "id" | "name" | "installationId"> & { stopngo: Maybe<StopNGo> } & { periodId: Maybe<ID> } & { topZoneId: Maybe<ID> } & { topZone?: Maybe<Zone> }
  >;
};

export const stopngoTokenData = gql`
  query stopngoTokenData($eventId: Int!) {
    stopngoTokenData(eventId: $eventId) {
      id
      name
      installationId
      topZoneId
      topZone {
        flowRate
      }
      periodId
      stopngo {
        limit
        lang
        logoUrl
        hasTechnisLogo
        soundConfig {
          enabled
          timeout
          type
          sounds {
            go
            overlimit
            stop
          }
        }
        customTexts {
          go {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
          stop {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
        }
        showroomConfig {
          enabled
          mediaLibrary {
            url
            type
            uploaded
          }
          slideshows {
            resources {
              mediaKey
              duration
            }
            until
          }
          unit
        }
      }
    }
  }
`;

export type GetStopNGoAPITokenResult = {
  getStopNGoAPIToken: Maybe<string>;
};

export const getStopNGoAPIToken = gql`
  query getStopNGoAPIToken($tokenId: String!) {
    getStopNGoAPIToken(tokenId: $tokenId)
  }
`;

/***
 * Mutations
 */

export type CreateStopNGoTokenResult = {
  createStopNGoToken: Maybe<StopNGoToken>;
};

export const createStopNGoToken = gql`
  mutation createStopNGoToken($token: StopNGoTokenCreateInput!) {
    createStopNGoToken(token: $token) {
      id
    }
  }
`;
