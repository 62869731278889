import {
  MaybeAll,
  mergeDeep,
  StopNGoShowroomConfigSlideshow,
  StopNGoShowroomConfigSlideshowMedia,
  StopNGoShowroomConfigSlideshowMediaTypes,
  StopNGoShowroomConfigSlideshowResource,
  StopNGoShowroomConfigSlideshowUnit,
} from "@technis/shared";
import React, { FunctionComponent, useEffect, useState } from "react";
import { StopNGoShowroomMediaToAddInput } from "../../../types/stopngo.mutations";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { FormSelect, OptionProps, renderOption } from "../common/form/FormSelect";
import { OnChangeConfiguration, StopNGoState } from "./Configuration";
import { ConfigurationSectionTitle } from "./ConfigurationSectionTitle";
import { ConfigurationMediaLibraryModal } from "./ConfigurationMediaLibraryModal";
import _ from "lodash";
import { ConfigurationShowroomConfigSlideshow } from "./ConfigurationShowroomConfigSlideshow";
import { getBase64MediaType, getExternalMediaType } from "../../utils/image";

export const DEFAULT_SLIDESHOW_IMAGE_DURATION = 5;
export const STOPNGO_SLIDESHOW_KEY = "stopngo";
export const STOPNGO_SLIDESHOW_URL = "https://storage.googleapis.com/technis-counting-stopngo-resources/Configuration-SlideshowOption.jpg";
export const MAX_PERCENT = 100;
export const MINUTES_IN_A_DAY = 1440;

const UNITS: OptionProps[] = [
  { value: StopNGoShowroomConfigSlideshowUnit.MINUTE, i18n: translation.common.minute },
  { value: StopNGoShowroomConfigSlideshowUnit.PERCENT, i18n: translation.common.percent },
  { value: StopNGoShowroomConfigSlideshowUnit.PERSON, i18n: translation.common.person },
];

export type UsableMedia = StopNGoShowroomConfigSlideshowMedia | StopNGoShowroomMediaToAddInput | string;
export type UpdateSlideshows = {
  newUnit?: StopNGoShowroomConfigSlideshowUnit;
  slideshowsToAdd?: Pick<StopNGoShowroomConfigSlideshow, "until">[];
  slideshowsToUpdate?: { [key: number]: Pick<StopNGoShowroomConfigSlideshow, "until"> };
  slideshowsToDelete?: number[];
  resourcesToAdd?: { [key: number]: { [key: number]: StopNGoShowroomConfigSlideshowResource } };
  resourcesToUpdate?: { [key: number]: { [key: number]: MaybeAll<StopNGoShowroomConfigSlideshowResource> } };
  resourcesToDelete?: { [key: number]: { [key: number]: boolean } };
  _mediasToAdd?: StopNGoState["showroomConfig"]["mediasToAdd"];
  _mediasToDelete?: StopNGoState["showroomConfig"]["mediasToDelete"];
  _externalMediasToAdd?: StopNGoState["showroomConfig"]["externalMediasToAdd"];
  _externalMediasToDelete?: StopNGoState["showroomConfig"]["externalMediasToDelete"];
};

export const isMediaNewFile = (media: UsableMedia): media is StopNGoShowroomMediaToAddInput => typeof media !== "string" && !!(media as StopNGoShowroomMediaToAddInput).data;
export const isMediaExternalMedia = (media: UsableMedia): media is string => !isMediaNewFile(media) && typeof media === "string";
export const findResourceInUsableMedias = (usableMedias: UsableMedia[], r: StopNGoShowroomConfigSlideshowResource) =>
  usableMedias.find(m => r.mediaKey === (isMediaNewFile(m) ? m.userMediaKey : isMediaExternalMedia(m) ? m : m.url)) ||
  (r.mediaKey === STOPNGO_SLIDESHOW_KEY ? STOPNGO_SLIDESHOW_KEY : undefined);
export const getUsableMediaInfos = (m: UsableMedia) => ({
  url: isMediaNewFile(m) ? m.data : isMediaExternalMedia(m) ? (m === STOPNGO_SLIDESHOW_KEY ? STOPNGO_SLIDESHOW_URL : m) : m.url,
  userMediaKey: isMediaNewFile(m) ? m.userMediaKey : null,
  type: isMediaNewFile(m)
    ? getBase64MediaType(m.data)
    : isMediaExternalMedia(m)
    ? m === STOPNGO_SLIDESHOW_KEY
      ? StopNGoShowroomConfigSlideshowMediaTypes.IMAGE
      : getExternalMediaType(m)
    : m.type,
});

type Props = {
  configuration: StopNGoState;
  onChange: OnChangeConfiguration;
};

const getUsableMedias = (
  mediaLibrary: StopNGoState["showroomConfig"]["mediaLibrary"],
  mediasToAdd: StopNGoState["showroomConfig"]["mediasToAdd"],
  mediasToDelete: StopNGoState["showroomConfig"]["mediasToDelete"],
  externalMediasToAdd: StopNGoState["showroomConfig"]["externalMediasToAdd"],
  externalMediasToDelete: StopNGoState["showroomConfig"]["externalMediasToDelete"],
): UsableMedia[] =>
  [
    ...mediaLibrary.filter(m => !mediasToDelete?.find(id => id === m.url) && !externalMediasToDelete?.find(id => id === m.url)),
    ...(mediasToAdd || []),
    ...(externalMediasToAdd || []),
  ].reverse();

export const ConfigurationShowroomConfig: FunctionComponent<Props> = props => {
  const { configuration, onChange } = props;
  const { limit, showroomConfig } = configuration;
  const { unit, slideshows, mediaLibrary, mediasToAdd, mediasToDelete, externalMediasToAdd, externalMediasToDelete } = showroomConfig;

  const getSlideshowSlotsStats = (newSlideshows?: typeof slideshows, newUnit?: StopNGoShowroomConfigSlideshowUnit) => {
    const unitToUse = newUnit || unit;
    const maxUntil = unitToUse === StopNGoShowroomConfigSlideshowUnit.MINUTE ? MINUTES_IN_A_DAY : unitToUse === StopNGoShowroomConfigSlideshowUnit.PERSON ? limit : MAX_PERCENT;
    return { maxUntil, freeSlideshowSlots: maxUntil - (newSlideshows || slideshows).length };
  };

  const updateSlideshows = ({
    newUnit,
    slideshowsToAdd,
    slideshowsToDelete,
    slideshowsToUpdate,
    resourcesToAdd,
    resourcesToDelete,
    resourcesToUpdate,
    _mediasToAdd,
    _mediasToDelete,
    _externalMediasToAdd,
    _externalMediasToDelete,
  }: UpdateSlideshows) => {
    let newSlideshows = _.cloneDeep(slideshows);

    // Apply modification
    if (resourcesToUpdate) {
      Object.typedKeys(resourcesToUpdate).forEach(slideshowKey => {
        const slideshow = newSlideshows[slideshowKey];
        if (!slideshow) return;
        const updates = resourcesToUpdate[slideshowKey];
        Object.typedKeys(updates).forEach(key => {
          const resource = slideshow.resources[key];
          if (!resource) return;
          const update = resourcesToUpdate[slideshowKey][key];
          newSlideshows[slideshowKey].resources[key] = mergeDeep(update, resource, ["duration"]);
        });
      });
    }
    if (slideshowsToUpdate) {
      Object.typedKeys(slideshowsToUpdate).forEach(key => {
        const slideshow = newSlideshows[key];
        if (!slideshow) return;
        const update = slideshowsToUpdate[key];
        newSlideshows[key] = { ...slideshow, ...update };
      });
    }
    if (resourcesToDelete) {
      Object.typedKeys(resourcesToDelete).forEach(slideshowKey => {
        const slideshow = newSlideshows[slideshowKey];
        if (!slideshow) return;
        const toDelete = Object.typedKeys(resourcesToDelete[slideshowKey]).map(Number);
        newSlideshows[slideshowKey].resources = slideshow.resources.filter((_, i) => toDelete.findIndex(d => i === d) === -1);
      });
    }
    newSlideshows = newSlideshows.filter((_, i) => !slideshowsToDelete?.find(d => i === d));
    if (resourcesToAdd) {
      Object.typedKeys(resourcesToAdd).forEach(slideshowKey => {
        const slideshow = newSlideshows[slideshowKey];
        if (!slideshow) return;
        const newResources = Object.values(resourcesToAdd[slideshowKey]);
        newSlideshows[slideshowKey].resources = [...slideshow.resources, ...newResources];
      });
    }
    slideshowsToAdd?.forEach(s => {
      newSlideshows.push({
        ...s,
        resources: [{ mediaKey: STOPNGO_SLIDESHOW_KEY, duration: DEFAULT_SLIDESHOW_IMAGE_DURATION }],
      });
    });

    // Verify comptability with mediaLibrary
    const allowedMedias = getUsableMedias(
      mediaLibrary,
      _mediasToAdd || mediasToAdd,
      _mediasToDelete || mediasToDelete,
      _externalMediasToAdd || externalMediasToAdd,
      _externalMediasToDelete || externalMediasToDelete,
    );
    newSlideshows = newSlideshows.filter(({ resources }, i) => {
      newSlideshows[i].resources = resources.filter(r => findResourceInUsableMedias(allowedMedias, r));
      return newSlideshows[i].resources.length > 0;
    });

    // Verify and correct slideshow order
    const unitToUse = newUnit || unit;
    const { maxUntil, freeSlideshowSlots } = getSlideshowSlotsStats(newSlideshows, unitToUse);
    if (freeSlideshowSlots > 0) {
      newSlideshows = newSlideshows.map((_, idx) => {
        const index = newUnit ? newSlideshows.length - 1 - idx : idx; // if newUnit, process array in reverse
        const slideshow = newSlideshows[index];
        let firstIteration = true;
        while (newSlideshows.find((s, i) => s.until === slideshow.until && i !== index) || slideshow.until > maxUntil) {
          if (firstIteration) {
            slideshow.until = maxUntil;
            firstIteration = false;
          }
          slideshow.until--;
        }
        return slideshow;
      });
    }
    newSlideshows = _.sortBy(newSlideshows, ["until"]);
    if (newSlideshows.length) {
      newSlideshows[newSlideshows.length - 1].until = maxUntil;
    } else {
      newSlideshows.push({
        until: maxUntil,
        resources: [{ mediaKey: STOPNGO_SLIDESHOW_KEY, duration: DEFAULT_SLIDESHOW_IMAGE_DURATION }],
      });
    }

    onChange({
      "showroomConfig.slideshows": newSlideshows,
      ...(newUnit ? { "showroomConfig.unit": newUnit } : {}),
      ...(_mediasToAdd ? { "showroomConfig.mediasToAdd": _mediasToAdd } : {}),
      ...(_mediasToDelete ? { "showroomConfig.mediasToDelete": _mediasToDelete } : {}),
      ...(_externalMediasToAdd ? { "showroomConfig.externalMediasToAdd": _externalMediasToAdd } : {}),
      ...(_externalMediasToDelete ? { "showroomConfig.externalMediasToDelete": _externalMediasToDelete } : {}),
    });
  };

  useEffect(() => {
    updateSlideshows({ newUnit: unit });
  }, [limit]);

  const { freeSlideshowSlots, maxUntil } = getSlideshowSlotsStats();

  const usableMedias = getUsableMedias(mediaLibrary, mediasToAdd, mediasToDelete, externalMediasToAdd, externalMediasToDelete);
  const [isMediaLibraryVisible, setIsMediaLibraryVisible] = useState(false);

  return (
    <>
      <div className="showroom">
        <ConfigurationSectionTitle title={i18n.t(translation.common.showroom)}>
          <div className="media-library-button" onClick={() => setIsMediaLibraryVisible(true)}>
            {i18n.t(translation.common.myMediaLibrary)}
          </div>
        </ConfigurationSectionTitle>
        <FormSelect
          label={i18n.t(translation.common.unit)}
          className="select"
          value={unit}
          onSelect={e => {
            updateSlideshows({ newUnit: e as StopNGoShowroomConfigSlideshowUnit });
          }}
        >
          {UNITS.map(renderOption)}
        </FormSelect>
        <div className="slideshows">
          {slideshows.map((slideshow, index) => (
            <ConfigurationShowroomConfigSlideshow
              key={`${slideshow.until}-${index}`}
              slideshow={slideshow}
              index={index}
              usableMedias={usableMedias}
              updateSlideshows={updateSlideshows}
              unit={unit}
              isLast={slideshows.length - 1 === index}
              toggleMediaLibrary={setIsMediaLibraryVisible}
            />
          ))}
        </div>
        {freeSlideshowSlots > 0 ? (
          <div
            className="add-slideshow-button"
            onClick={() => {
              updateSlideshows({ slideshowsToAdd: [{ until: maxUntil }] });
            }}
          >
            <span className="title">+ {i18n.t(translation.edit.showroomConfig.addSlideshow)}</span>
          </div>
        ) : null}
      </div>
      <ConfigurationMediaLibraryModal
        configuration={configuration}
        onChange={onChange}
        usableMedias={usableMedias}
        visible={isMediaLibraryVisible}
        onClose={() => setIsMediaLibraryVisible(false)}
        updateSlideshows={updateSlideshows}
      />
    </>
  );
};
