/* eslint-disable max-len */
import React from "react";

interface Props {
  color?: string;
  className?: string;
}

export const StopWatch = (props: Props) => {
  const { color, className } = props;
  return (
    <svg fill={color} className={className} x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M424.166,104.9c-3.328-3.337-8.738-3.337-12.066,0l-30.848,30.848c-3.337,3.336-3.337,8.73,0,12.066
			c1.664,1.664,3.849,2.5,6.033,2.5c2.176,0,4.361-0.828,6.033-2.5l30.848-30.848C427.503,113.63,427.503,108.237,424.166,104.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M449.766,130.5l-51.2-51.2c-3.328-3.337-8.738-3.337-12.066,0c-3.336,3.337-3.336,8.73,0,12.066l51.2,51.2
			c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5C453.103,139.23,453.103,133.837,449.766,130.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M130.748,135.748L99.9,104.9c-3.337-3.337-8.738-3.337-12.066,0c-3.337,3.337-3.337,8.73,0,12.066l30.848,30.848
			c1.664,1.664,3.849,2.5,6.033,2.5s4.369-0.828,6.033-2.5C134.084,144.478,134.084,139.085,130.748,135.748z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M125.5,79.3c-3.328-3.337-8.738-3.337-12.066,0l-51.2,51.2c-3.337,3.336-3.337,8.73,0,12.066
			c1.664,1.664,3.849,2.5,6.033,2.5s4.369-0.836,6.033-2.5l51.2-51.2C128.836,88.03,128.836,82.637,125.5,79.3z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M307.2,0H204.8c-4.71,0-8.533,3.823-8.533,8.533V51.2c0,4.71,3.823,8.533,8.533,8.533h102.4
			c4.71,0,8.533-3.823,8.533-8.533V8.533C315.733,3.823,311.91,0,307.2,0z M298.667,42.667h-85.333v-25.6h85.333V42.667z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,85.333c-117.632,0-213.333,95.701-213.333,213.333S138.368,512,256,512s213.333-95.701,213.333-213.333
			S373.632,85.333,256,85.333z M256,494.933c-108.22,0-196.267-88.047-196.267-196.267S147.78,102.4,256,102.4
			s196.267,88.047,196.267,196.267S364.22,494.933,256,494.933z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M273.067,42.667h-34.133c-4.71,0-8.533,3.823-8.533,8.533v42.667c0,4.71,3.823,8.533,8.533,8.533h34.133
			c4.71,0,8.533-3.823,8.533-8.533V51.2C281.6,46.49,277.777,42.667,273.067,42.667z M264.533,85.333h-17.067v-25.6h17.067V85.333z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
