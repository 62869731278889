import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { AppThemes } from "../components/common/Theme";
import { saveToken } from "../redux/auth/auth.actions";
import { SECONDS_IN_YEAR } from "../utils/date";

const [domain, extension] = window.location.hostname.split(".").splice(-2);
const domainPrefix = extension ? "." : "";
const domainSuffix = extension ? `.${extension}` : "";

const COOKIE_PREFIX = process.env.LINK_ENV === "production" ? "" : `${process.env.LINK_ENV}-`;
export const COOKIE_DOMAIN = `${domainPrefix}${domain}${domainSuffix}`;
export const COOKIE_CONFIGURATION_TOKEN = `${COOKIE_PREFIX}configurationToken`;
export const COOKIE_STOPNGO_TOKEN = `${COOKIE_PREFIX}stopngoToken`;
export const COOKIE_THEME = "themeTechnis";

export const useCookieToken = (cookieKey: string) => {
  const [cookies] = useCookies([]);
  const cookieToken: string = cookies[cookieKey];
  useEffect(() => {
    if (cookieToken) {
      saveToken(cookieToken);
    }
  }, [cookieToken]);
  return cookieToken;
};

export const useCookieTheme = () => {
  const [cookies, setCookie] = useCookies([]);
  const setThemeCookie = (theme: AppThemes) =>
    setCookie(COOKIE_THEME, theme, {
      path: "/",
      maxAge: SECONDS_IN_YEAR,
      domain: COOKIE_DOMAIN,
      secure: true,
    });
  return [cookies[COOKIE_THEME], setThemeCookie];
};
