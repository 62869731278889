import { inEnum, UserLanguage } from "@technis/shared";
import React, { FunctionComponent, useEffect } from "react";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { eventById, EventByIdResult } from "../../graphql/event.gql";
import { userMe, UserMeResult } from "../../graphql/user.gql";
import { setLanguage } from "../../lang/i18n";
import { RootState } from "../../redux/store";
import { ErrorSelection, NoEventError, NotAuthorizedError, NoTokenError } from "../common/error/Errors";
import { Loader } from "../common/svg/Loader";
import { StopNGoConfiguration } from "./Configuration";
import classNames from "classnames";
import { COOKIE_CONFIGURATION_TOKEN, useCookieToken } from "../../hooks/token";
import { TrackingViewActivityName, TrackingViewActivityTopic } from "../../utils/constants";
import { withTracking } from "../../hoc/withTracking";

const POLL_INTERVAL = 10000;

type StopNGoContainerProps = RouteComponentProps<{ eventId: string }>;

export const ConfigurationContainer: FunctionComponent<StopNGoContainerProps> = withTracking(props => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.trackPageActivity(TrackingViewActivityTopic.stopNGoConfiguration, TrackingViewActivityName.stopNGoConfiguration);
  }, []);

  const eventId = Number(params.eventId) || undefined;
  const cookieToken = useCookieToken(COOKIE_CONFIGURATION_TOKEN);
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <div className={classNames("configuration-container")}>
      {cookieToken ? token ? <DataFetching token={token} eventId={eventId} /> : null : <ErrorSelection DefaultError={NoTokenError} />}
    </div>
  );
});

type StopNGoDataFetchingProps = {
  token: string;
  eventId: number | undefined;
};

const DataFetching: FunctionComponent<StopNGoDataFetchingProps> = props => {
  const { token, eventId } = props;

  const eventQuery = useQuery<EventByIdResult>(eventById, {
    variables: { eventId },
    fetchPolicy: "network-only",
    skip: !token,
  });
  const userQuery = useQuery<UserMeResult>(userMe, { fetchPolicy: "network-only", skip: !token });
  const event = eventQuery.data?.eventById;
  const user = userQuery.data?.me;

  const refetchData = () => {
    eventQuery.refetch();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetchData();
    }, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    refetchData();
  }, [token]);

  const lang = user?.lang;

  // Language
  useEffect(() => {
    if (inEnum(UserLanguage, lang)) {
      setLanguage(lang);
    }
  }, [lang]);

  if (eventQuery.loading || userQuery.loading) {
    return <Loader style={{ justifySelf: "center", alignSelf: "center" }} />;
  } else if (!event || eventQuery.error) {
    return <ErrorSelection message={eventQuery.error?.message} DefaultError={NoEventError} errorClassName="" />;
  } else if (!event.canEditStopNGo) {
    return <ErrorSelection DefaultError={NotAuthorizedError} errorClassName="" />;
  } else {
    return <StopNGoConfiguration event={event} configuration={event.stopngo} refetchData={refetchData} />;
  }
};
