import React, { FunctionComponent } from "react";

import { FormErrors, FormErrorsProps } from "./FormErrors";
import classNames from "classnames";
import { TooltipForm } from "./TooltipForm";

export type HelperSharedProps = {
  helperText?: string;
};

export type LabelSharedProps = {
  label?: string;
  labelNextToInput?: boolean;
  itemClassName?: string;
  style?: React.CSSProperties;
};

type Props = {
  children: React.ReactNode;
};

export const FormItem: FunctionComponent<Props & LabelSharedProps & FormErrorsProps & HelperSharedProps> = ({
  label,
  children,
  errors,
  itemClassName = "",
  labelNextToInput,
  style,
  helperText,
}) => {
  const formItem = (
    <div className={classNames("form-item", itemClassName, { "next-to-input": !!labelNextToInput })} style={style}>
      {label && (
        <span className="form-label">
          {label}
          {helperText && <TooltipForm tooltipText={helperText} />}
        </span>
      )}
      {children}
    </div>
  );
  return errors?.length ? (
    <div>
      {formItem}
      <FormErrors errors={errors} />
    </div>
  ) : (
    formItem
  );
};
