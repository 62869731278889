import { AppThemes } from "../components/common/Theme";
import { AQUA, BLACK, GRAY_80, GREEN, HEX_OPACITY, LIGHT_BLUE, LIGHT_GRAY, WHITE } from "@technis/shared";

export type Color = keyof typeof Colors;

export const Colors = Object.freeze({
  WHITE: WHITE,
  LIGHT_WHITE: LIGHT_GRAY,
  DISABLED_WHITE: WHITE + HEX_OPACITY[10],
  GRAY_100: "#EFEFEF",
  GRAY_200: "#DBDBDB",
  GRAY_300: "#B4B4B4",
  GRAY_400: "#9E9E9E",
  GRAY_500: "#8B8B8B",
  GRAY_600: "#737373",
  GRAY_700: "#5B5B5B",
  GRAY_800: "#414141",
  GRAY_900: "#212121",
  BLACK: "#131313",
  PURE_BLACK: BLACK,
  LIGHT_BLACK: GRAY_80,

  BLUE: "#005AF0",
  GREEN: GREEN,
  PURPLE: "#A537ED",
  YELLOW: "#FFC433",
  ORANGE: "#ee8245",
  DARK_ORANGE: "#BB4602",
  RED: "#FF302C",
  LIGHT_RED: "#ff4444",

  LIGHT_BLUE: "#588de7",
  AQUA: "#1ed2e3",
  DARK_BLUE: "#061030",
  REGAL_BLUE: "#121C3B",
  MAGIC_BLUE: "#005aee",

  DARK_GRAY: "#A7A7A7",
  LIGHT_GRAY: "#F8F8F8",

  PRIMARY: GREEN,
  SECONDARY: "#061030",

  LIGHT_GRADIENT_START: LIGHT_BLUE,
  LIGHT_GRADIENT_END: LIGHT_BLUE,
  LIGHT_BACKGROUND_GRADIENT: `linear-gradient(${AQUA}, ${LIGHT_BLUE})`,
});

type ThemeColors = {
  gradients: Color[];
  disabledBackground: Color;
  disabledColor: Color;
  backgroundGradient: Color;
  reverseFont: Color;
};

const darkThemeColors = {
  gradients: [Colors.AQUA, Colors.LIGHT_BLUE],
  disabledBackground: Colors.DISABLED_WHITE,
  disabledColor: "inherit",
  backgroundGradient: Colors.LIGHT_BACKGROUND_GRADIENT,
  reverseFont: Colors.WHITE,
};
const lightThemeColors = {
  gradients: [Colors.LIGHT_BLUE, Colors.MAGIC_BLUE],
  disabledBackground: Colors.LIGHT_WHITE,
  disabledColor: Colors.LIGHT_BLACK,
  backgroundGradient: Colors.LIGHT_BACKGROUND_GRADIENT,
  reverseFont: Colors.WHITE,
};
// TODO: Wait for computed enum values
export const getThemeColors = (theme: AppThemes) => ((theme === AppThemes.LIGHT ? lightThemeColors : darkThemeColors) as unknown) as ThemeColors;
