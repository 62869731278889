import React, { FunctionComponent } from "react";
import { Color } from "../../utils/colors";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  iconComponent: React.ComponentElement<any, any>;
  gradientColors: Color[];
};

export const GradientIcon: FunctionComponent<Props> = ({ iconComponent, gradientColors: [start, end] }) => {
  const gradientId = `${start}-${end}`;
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id={gradientId} x1="50%" y1="0%" x2="50%" y2="100%">
          <stop stopColor={start} offset="0%" />
          <stop stopColor={end} offset="100%" />
        </linearGradient>
      </svg>
      {React.cloneElement(iconComponent, { style: { fill: `url(#${gradientId})` } })}
    </>
  );
};
