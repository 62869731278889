import * as React from "react";
import { FunctionComponent } from "react";
import { Parser } from "./Parsers";

interface ParsingResult {
  start: number;
  end: number;
  matched: JSX.Element;
}

interface Props {
  parsers: Parser[];
  children: string;
}

export const ParsedText: FunctionComponent<Props> = props => {
  const { children, parsers, ...textProps } = props;

  const handleParser = (text: string, { pattern, render }: Parser) => {
    const recursiveHandling = (left: string, acc: ParsingResult[]): ParsingResult[] => {
      const match = pattern.exec(left);
      if (!match) {
        return acc;
      }
      const delta = text.length - left.length;
      const start = delta + match.index;
      const end = start + match[0].length;

      const result = {
        start,
        end,
        matched: render(match[0], start),
      };
      return recursiveHandling(left.slice(end - delta), acc.concat(result));
    };

    return recursiveHandling(text, []);
  };

  const build = (text: string, parsing: ParsingResult[]) => {
    const built: { left: string; results: Array<string | JSX.Element> } = parsing
      .sort((a, b) => a.start - b.start)
      .filter((r, index, arr) => arr.slice(0, index).every(sub => r.start > sub.end))
      .reduce(
        (acc: { left: string; results: Array<string | JSX.Element> }, { start, end, matched }) => {
          const delta = text.length - acc.left.length;
          const unmatched = acc.left.slice(0, start - delta);
          const left = acc.left.slice(end - delta);
          return { left, results: acc.results.concat(unmatched, matched) };
        },
        {
          left: text,
          results: [],
        },
      );
    return built.results.concat(built.left);
  };

  const parse = (text: string, parsers: Parser[]) => {
    const parsed = parsers.reduce((x, y) => [...x, ...handleParser(text, y)], [] as ParsingResult[]);
    return build(text, parsed);
  };

  return <div {...textProps}>{parse(typeof children === "string" ? children : children[0], parsers)}</div>;
};
