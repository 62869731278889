import { NeededOnly, Maybe, User } from "@technis/shared";
import gql from "graphql-tag";

export type UserMeResult = {
  me: Maybe<NeededOnly<User, "id" | "name" | "role" | "lang">>;
};

export const userMe = gql`
  query userMe {
    me {
      id
      name
      role
      lang
    }
  }
`;
