import { Event, StopNGo, NeededOnly, Maybe } from "@technis/shared";
import gql from "graphql-tag";

export type EventByIdResult = {
  eventById: Maybe<NeededOnly<Event, "id" | "name" | "installationId"> & { stopngo: Maybe<StopNGo> } & { canEditStopNGo?: Maybe<boolean> }>;
};

export const eventById = gql`
  query eventById($eventId: Int!) {
    eventById(eventId: $eventId) {
      id
      name
      installationId
      stopngo {
        limit
        lang
        logoUrl
        hasTechnisLogo
        soundConfig {
          enabled
          timeout
          type
          sounds {
            go
            overlimit
            stop
          }
        }
        customTexts {
          go {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
          stop {
            title
            message
            iconUrl
            styles {
              backgroundColors
              titleColor
              messageColor
            }
          }
        }
        showroomConfig {
          enabled
          mediaLibrary {
            url
            type
            uploaded
          }
          slideshows {
            resources {
              mediaKey
              duration
            }
            until
          }
          unit
        }
      }
      canEditStopNGo
    }
  }
`;
