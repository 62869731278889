import React, { FunctionComponent } from "react";
import classNames from "classnames";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const SquareDiv: FunctionComponent<Props> = ({ className, children, ...otherProps }) => (
  <div className={classNames("square-div", className)} {...otherProps}>
    <div className="content">{children}</div>
  </div>
);
