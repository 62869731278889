import { Indexable, Maybe, StopNGo, StopNGoShowroomConfigSlideshowUnit, StopNGoSoundType } from "@technis/shared";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { StopNGoCreateInput, StopNGoUpdateInput } from "../../../types/stopngo.mutations";
import { gql } from "../../graphql";
import { i18n, SupportedLanguages } from "../../lang/i18n";
import { clone, diff, gqlValue, set } from "../../utils/utils";
import { ConfigurationCustomization } from "./ConfigurationCustomization";
import { ConfigurationGeneral } from "./ConfigurationGeneral";
import { ConfigurationShowroomConfig, STOPNGO_SLIDESHOW_KEY } from "./ConfigurationShowroomConfig";
import { ConfigurationSound } from "./ConfigurationSound";
import { ConfigurationPreview } from "./ConfigurationPreview";
import { EventByIdResult } from "../../graphql/event.gql";
import { useWindowSize } from "../../hooks/windowSize";
import classNames from "classnames";
import { translation } from "../../lang/translation";
import { Message } from "../common/Message";
import { useCookies } from "react-cookie";
import { COOKIE_STOPNGO_TOKEN, COOKIE_DOMAIN } from "../../hooks/token";

import { AppThemes, ThemeContext } from "../common/Theme";
import { FaMoon, FaSun } from "react-icons/all";

export type StopNGoState = StopNGo & StopNGoUpdateInput & StopNGoCreateInput;

const DEFAULT_CONFIGURATION = ({
  eventId: null,
  lang: SupportedLanguages.ENGLISH,
  limit: null,
  logoUrl: undefined,
  logoToUpload: undefined,
  soundConfig: {
    enabled: true,
    sounds: {
      go: true,
      stop: true,
      overlimit: true,
    },
    type: StopNGoSoundType.VOICE,
    timeout: null,
  },
  customTexts: {
    go: {
      title: null,
      message: null,
      iconUrl: undefined,
      iconToUpload: undefined,
      styles: {
        backgroundColors: [],
        titleColor: null,
        messageColor: null,
      },
    },
    stop: {
      title: null,
      message: null,
      iconUrl: undefined,
      iconToUpload: undefined,
      styles: {
        backgroundColors: [],
        titleColor: null,
        messageColor: null,
      },
    },
  },
  showroomConfig: {
    enabled: false,
    mediaLibrary: [],
    slideshows: [{ resources: [{ duration: 5, mediaKey: STOPNGO_SLIDESHOW_KEY }], until: 100 }],
    unit: StopNGoShowroomConfigSlideshowUnit.PERCENT,
  },
  // Strict null checks don't allow backend default
} as never) as StopNGoState;

type Props = {
  event: NonNullable<EventByIdResult["eventById"]>;
  configuration: Maybe<StopNGo>;
  refetchData: () => void;
};

export type OnChangeConfiguration = (updates: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: Maybe<string | number | boolean | any[] | Indexable<any>>;
}) => void;

export const StopNGoConfiguration: FunctionComponent<Props> = props => {
  const { configuration, event, refetchData } = props;
  const [state, setState] = useState<StopNGoState>(clone(configuration ? (configuration as StopNGoState) : DEFAULT_CONFIGURATION));

  useEffect(() => {
    setHasChanges(false);
    setState(clone(configuration ? (configuration as StopNGoState) : DEFAULT_CONFIGURATION));
  }, [configuration]);

  const [hasChanges, setHasChanges] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange: OnChangeConfiguration = updates => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setState(Object.keys(updates).reduce((acc, path) => set(acc, path, gqlValue(updates[path])), state));
  };

  const [isSaving, setIsSaving] = useState(false);

  const onSave = () => {
    if (!event.id || isSaving) return;
    setIsSaving(true);
    (() => {
      if (configuration) {
        const updates = diff(configuration, state);
        const isNoLimit = updates.limit === null;

        return gql.StopNGo.update({
          ...updates,
          eventId: event.id,
          showroomConfig: isNoLimit ? undefined : { ...updates.showroomConfig, slideshows: state.showroomConfig.slideshows },
        });
      }
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        showroomConfig: { mediaLibrary, ...restShowroom },
        ...restState
      } = state;
      return gql.StopNGo.create({ ...restState, showroomConfig: restShowroom, eventId: event.id });
    })()
      .then(() => {
        refetchData();
        Message.success({ text: i18n.t(translation.edit.saveSuccess) });
      })
      .catch(() => {
        Message.error({ text: i18n.t(translation.edit.saveError) });
      })
      .finally(() => setIsSaving(false));
  };

  const [, setCookie] = useCookies([]);

  const openStopNGo = async () => {
    const createStopNGoTokenQuery = await gql.StopNGoToken.create({
      installationId: event.installationId,
      temporary: true,
    });
    const tokenId = createStopNGoTokenQuery.data?.createStopNGoToken?.id;
    if (!tokenId) return;
    const getStopNGoAPITokenQuery = await gql.StopNGoToken.getAPIToken(tokenId);
    const token = getStopNGoAPITokenQuery.data?.getStopNGoAPIToken;
    if (!token) return;
    const endpoint = process.env.ENV === "local" ? "http://localhost:8080" : `${window.location.protocol}//${window.location.hostname}`;
    setCookie(COOKIE_STOPNGO_TOKEN, token, { domain: COOKIE_DOMAIN, path: "/" });
    setTimeout(() => {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: `${endpoint}/${event.id}`,
        rel: "noopener noreferrer",
      }).click();
    }, 1);
  };

  const windowSize = useWindowSize();
  const isSmallScreenClassName = { "small-screen": (windowSize.width && windowSize.width < 800) || true }; // Remove true when implementing preview
  const [theme, setTheme] = useContext(ThemeContext);
  return (
    <>
      <div className={classNames("configuration-form", "main-form", isSmallScreenClassName)}>
        <div className="container">
          <div className="theme-icon" onClick={() => setTheme(theme === AppThemes.LIGHT ? AppThemes.DARK : AppThemes.LIGHT)}>
            {theme === AppThemes.LIGHT ? <FaMoon /> : <FaSun />}
          </div>
          <h1>{event.name}</h1>
          <ConfigurationGeneral configuration={state} onChange={onChange} />
          <ConfigurationCustomization configuration={state} onChange={onChange} />
          <ConfigurationShowroomConfig configuration={state} onChange={onChange} />
          <ConfigurationSound configuration={state} onChange={onChange} />
          <div className="buttons two-col">
            <div className="open-stopngo" onClick={openStopNGo}>
              <span>{i18n.t(hasChanges ? translation.edit.openPreviousStopNGo : translation.edit.openStopNGo)}</span>
            </div>
            {hasChanges ? (
              <div className={classNames("save", { saving: isSaving })} onClick={onSave}>
                <span>{i18n.t(isSaving ? translation.common.saving : translation.common.save)}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <ConfigurationPreview className={isSmallScreenClassName} configuration={state} />
    </>
  );
};
