import React, { FunctionComponent, useState } from "react";
import { createUID } from "../../../utils/utils";
import { Tooltip } from "antd";
import { IoIosHelpCircleOutline } from "react-icons/all";
import { ParsedText } from "../../lang/ParsedText";
import { formItemTooltipParser } from "../../lang/Parsers";
import { Colors } from "../../../utils/colors";

interface Props {
  tooltipText: string;
}

export const TooltipForm: FunctionComponent<Props> = props => {
  const { tooltipText } = props;
  const [id] = useState(createUID());
  const parsedText = <ParsedText parsers={[formItemTooltipParser()]}>{tooltipText}</ParsedText>;

  return (
    <div id={id} className="form-helper">
      <Tooltip title={parsedText} getPopupContainer={id ? () => document.getElementById(id) as HTMLElement : undefined} placement={"topLeft"}>
        <IoIosHelpCircleOutline color={Colors.LIGHT_BLUE} />
      </Tooltip>
    </div>
  );
};
