import React, { FunctionComponent, useEffect } from "react";
import { Route, Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { ConfigurationContainer } from "../configuration/ConfigurationContainer";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { PageError404 } from "../page-errors/PageErrors";
import { StopNGoContainer } from "../stopngo/StopNGoContainer";
import { COOKIE_STOPNGO_TOKEN, COOKIE_DOMAIN } from "../../hooks/token";
import { saveToken } from "../../redux/auth/auth.actions";
import { TrackingViewActivityName, TrackingViewActivityTopic } from "../../utils/constants";

import { getTranslationDetails } from "../../redux/app/app.actions";

export const MAIN_ROUTE_PATHS = {
  stopngoV1: "/:eventId/:token?",
  stopngoForceUrlToken: "/force-url-token/:eventId/:token",
  stopngoV0: "/:lang/:eventId/:token",
  configuration: "/configuration/:eventId",
  future: "/future/:eventId/:token?",
};

export const MainRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTranslationDetails());
  }, []);

  return (
    <Switch>
      <Route path={MAIN_ROUTE_PATHS.configuration} exact component={ConfigurationContainer} />
      <Route path={MAIN_ROUTE_PATHS.stopngoForceUrlToken} exact component={ForceUrlTokenStopNGo} />
      <Route path={MAIN_ROUTE_PATHS.future} exact component={StopNGoFuture} />
      <Route path={MAIN_ROUTE_PATHS.stopngoV0} exact component={OldStopNGoRedirect} />
      <Route path={MAIN_ROUTE_PATHS.stopngoV1} exact component={StopNGoTokenInCookieRedirect} />
      <Route component={PageError404} />
    </Switch>
  );
};

type OldStopNGoRedirectProps = RouteComponentProps<{ lang: string; eventId: string; token: string }>;
const OldStopNGoRedirect: FunctionComponent<OldStopNGoRedirectProps> = ({
  match: {
    params: { eventId, token },
  },
}) => <Redirect to={`/${eventId}/${token}`} />;

type ForceUrlTokenStopNGoProps = RouteComponentProps<{ eventId: string; token: string }>;
const ForceUrlTokenStopNGo: FunctionComponent<ForceUrlTokenStopNGoProps> = props => {
  saveToken(props.match.params.token);

  return <StopNGoContainer {...props} forceUrlToken />;
};

type StopNGoTokenInCookieRedirectProps = RouteComponentProps<{ eventId: string; token?: string }>;
const StopNGoTokenInCookieRedirect: FunctionComponent<StopNGoTokenInCookieRedirectProps> = props => {
  const {
    match: {
      params: { eventId, token },
    },
  } = props;
  const [, setCookie] = useCookies([]);

  if (token) {
    setCookie(COOKIE_STOPNGO_TOKEN, token, { domain: COOKIE_DOMAIN, path: "/" });
    return <Redirect to={`/${eventId}`} />;
  }
  return <StopNGoContainer {...props} />;
};

type StopNGoFutureProps = RouteComponentProps<{ eventId: string }>;
const StopNGoFuture: FunctionComponent<StopNGoFutureProps> = props => <StopNGoContainer isFuture {...props} />;
