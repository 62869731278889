import { Maybe, NeededOnly, Zone } from "@technis/shared";
import gql from "graphql-tag";

type ZoneWithFlowRate = Zone & { flowRate?: number };

export type ZonesByInstallationResult = {
  zonesByInstallation: Maybe<Array<NeededOnly<ZoneWithFlowRate, never, "id" | "parentIds" | "name" | "flowRate">>>;
};

export const zonesByInstallation = gql`
  query zonesByInstallation($installationId: Int!) {
    zonesByInstallation(installationId: $installationId) {
      id
      name
      parentIds
      topZone {
        flowRate
      }
    }
  }
`;
