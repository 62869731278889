import { Period, NeededOnly, Maybe } from "@technis/shared";
import gql from "graphql-tag";

export type PeriodByEventResult = { periodByEvent: Maybe<NeededOnly<Period, never, "id" | "name" | "dateBegin" | "dateEnd">> };
export const periodByEvent = gql`
  query periodByEvent($eventId: Int!, $date: Float!) {
    periodByEvent(eventId: $eventId, date: $date) {
      id
      name
      dateBegin
      dateEnd
    }
  }
`;
