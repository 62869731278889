import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Count,
  Maybe,
  StopNGo,
  StopNGoCustomText,
  StopNGoShowroomConfigSlideshow,
  StopNGoShowroomConfigSlideshowMedia,
  StopNGoShowroomConfigSlideshowMediaTypes,
  StopNGoShowroomConfigSlideshowResource,
  StopNGoShowroomConfigSlideshowUnit,
} from "@technis/shared";
import ReactPlayer from "react-player";
import { useInterval } from "../../hooks/interval";
import { getMinutesFromBeginningDay } from "../../utils/stopngo";
import { StopNGoOfficialMessage } from "./StopNGoOfficialMessage";
import { STOPNGO_SLIDESHOW_KEY } from "../configuration/ConfigurationShowroomConfig";
import classNames from "classnames";

type Props = {
  showroomConfig: StopNGo["showroomConfig"];
  count: Pick<Count, "in" | "out">;
  isBiggerThanLimit: boolean;
  numOfAllowedEntries: number;
  limit: number;
  eventName: string;
  customText: StopNGoCustomText;
  lang: string;
  logoUrl: string;
  hasTechnisLogo: boolean;
  isSplitStopNGo?: boolean;
  flowRate?: number;
};

type LoadedSlideshow = StopNGoShowroomConfigSlideshow & { resources: Array<StopNGoShowroomConfigSlideshowResource & { type: Maybe<StopNGoShowroomConfigSlideshowMedia["type"]> }> };

type State = {
  loadedSlideshows: LoadedSlideshow[] | undefined;
  currentSlideshowIndex: number;
  currentResourceIndex: number;
  remainingIntervalTs: number | null;
  mediaId: string;
};

const DEFAULT_SLIDESHOWS: LoadedSlideshow[] = [
  {
    resources: [{ mediaKey: STOPNGO_SLIDESHOW_KEY, duration: undefined, type: null }],
    until: 100,
  },
];

const createMediaId = (mediaKey: string, index: number) => `${mediaKey}-${index}`;

export const StopNGoSlideshow: FunctionComponent<Props> = props => {
  const {
    showroomConfig: { mediaLibrary, slideshows, unit },
    count,
    isBiggerThanLimit,
    numOfAllowedEntries,
    limit,
    isSplitStopNGo,
    flowRate,
    ...officialMessageProps
  } = props;

  const [state, setState] = useState<State>({
    loadedSlideshows: undefined,
    currentSlideshowIndex: 0,
    currentResourceIndex: 0,
    remainingIntervalTs: null,
    mediaId: "",
  });

  const { loadedSlideshows, currentSlideshowIndex, currentResourceIndex, remainingIntervalTs, mediaId } = state;

  const usableSlideshows = loadedSlideshows || DEFAULT_SLIDESHOWS;

  const isSelectingSlideshowRef = useRef(false);
  const selectSlideshowFromCount = (forcedSlideshows?: LoadedSlideshow[]) => {
    if ((!forcedSlideshows && !loadedSlideshows) || isSelectingSlideshowRef.current) {
      return;
    }
    isSelectingSlideshowRef.current = true;
    const slideshowsToUse = forcedSlideshows || usableSlideshows;
    const value =
      unit === StopNGoShowroomConfigSlideshowUnit.PERSON
        ? count.in - count.out
        : unit === StopNGoShowroomConfigSlideshowUnit.MINUTE
        ? getMinutesFromBeginningDay()
        : Math.floor(((count.in - count.out) * 100) / limit);
    let nextSlideshowIndex = slideshowsToUse.findIndex(s => value <= s.until);
    nextSlideshowIndex = nextSlideshowIndex === -1 ? currentSlideshowIndex % usableSlideshows.length : nextSlideshowIndex;
    const nextSlideshow = slideshowsToUse[nextSlideshowIndex];
    const nextResourceIndex = nextSlideshowIndex !== currentSlideshowIndex ? 0 : currentResourceIndex % nextSlideshow.resources.length;
    setState(prev => ({
      ...prev,
      loadedSlideshows: forcedSlideshows || prev.loadedSlideshows,
      currentSlideshowIndex: nextSlideshowIndex,
      currentResourceIndex: nextResourceIndex,
      mediaId: createMediaId(slideshowsToUse[nextSlideshowIndex].resources[nextResourceIndex].mediaKey, nextResourceIndex),
    }));
    isSelectingSlideshowRef.current = false;
  };

  const loadNewSlideshows = () => {
    const newSlideshows = slideshows.map(s => ({
      ...s,
      resources: s.resources.map(r => ({
        ...r,
        type: mediaLibrary.find(m => m.url === r.mediaKey)?.type,
      })),
    }));
    selectSlideshowFromCount(newSlideshows);
  };
  useEffect(() => {
    loadNewSlideshows();
  }, [mediaLibrary, slideshows]);
  const inside = count.in - count.out;
  useEffect(() => {
    selectSlideshowFromCount();
  }, [unit, inside]);
  useInterval(
    () => {
      selectSlideshowFromCount();
    },
    unit === StopNGoShowroomConfigSlideshowUnit.MINUTE ? 60000 : 0,
  );

  const resource = usableSlideshows[currentSlideshowIndex].resources[currentResourceIndex];
  const intervalMs = (resource?.duration || 0) * 1000;

  const lastIntervalStartTsRef = useRef<number | null>(null);
  const setIntervalStartTsRefNow = () => {
    lastIntervalStartTsRef.current = Date.now();
  };
  const setRemainingIntervalTsRef = () => {
    if (lastIntervalStartTsRef.current) {
      const remaining = Date.now() - lastIntervalStartTsRef.current - (remainingIntervalTs || intervalMs);
      setState(prev => ({ ...prev, remainingIntervalTs: remaining >= 0 ? null : Math.abs(remaining) }));
    }
  };
  useEffect(() => {
    if (isBiggerThanLimit) {
      setRemainingIntervalTsRef();
    }
  }, [isBiggerThanLimit]);

  const videoPlayerRef = useRef<ReactPlayer | null>(null);

  const setNextMedia = () => {
    const resources = usableSlideshows[currentSlideshowIndex].resources;
    const nextIndex = (currentResourceIndex + 1) % resources.length;
    const nextMedia = resources[nextIndex];
    const isNextMediaVideo = nextMedia?.type === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO;
    const nextMediaId = createMediaId(nextMedia.mediaKey, nextIndex);
    setState(prev => ({ ...prev, currentResourceIndex: nextIndex, mediaId: nextMediaId, remainingIntervalTs: null }));
    if (nextMediaId === mediaId && isNextMediaVideo && videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(0, "seconds");
    }
  };

  useInterval(
    () => {
      setNextMedia();
      setIntervalStartTsRefNow();
    },
    isBiggerThanLimit || (resource && resource.type === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO) ? 0 : remainingIntervalTs || intervalMs,
    () => {
      setIntervalStartTsRefNow();
    },
  );

  // Little fix to wait for the raspberrypi to init before showing media
  const [delayedRender, setDelayedRender] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setDelayedRender(false);
    }, 0);
  }, []);

  if (delayedRender || !resource) {
    return null;
  }

  const shouldShowOfficialMessage = isBiggerThanLimit || !resource.type;
  const isResourceImage = resource.type === StopNGoShowroomConfigSlideshowMediaTypes.IMAGE;
  const isResourceVideo = resource.type === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO;
  const shouldShowImage = isResourceImage && !shouldShowOfficialMessage;
  const shouldPlayVideo = isResourceVideo && !shouldShowOfficialMessage;

  return (
    <>
      <div className={classNames("fullscreen", { hidden: !!shouldShowOfficialMessage })}>
        <img key="image" src={resource.mediaKey} className="media" style={!shouldShowImage ? { display: "none" } : {}} />
        <ReactPlayer
          key="video"
          ref={videoPlayerRef}
          url={isResourceVideo ? resource.mediaKey : undefined}
          playing={shouldPlayVideo}
          className="media"
          width="100%"
          height="100%"
          onEnded={() => shouldPlayVideo && setNextMedia()}
          onError={(...e) => {
            if (shouldPlayVideo) {
              console.log(e);
              setNextMedia();
            }
          }}
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                modestbranding: 1,
              },
            },
          }}
          style={!shouldPlayVideo ? { display: "none" } : {}}
        />
      </div>
      <StopNGoOfficialMessage
        hidden={!shouldShowOfficialMessage}
        {...officialMessageProps}
        limit={limit}
        isBiggerThanLimit={isBiggerThanLimit}
        numOfAllowedEntries={numOfAllowedEntries}
        isSplitStopNGo={isSplitStopNGo}
        flowRate={flowRate}
        count={count}
      />
    </>
  );
};
