import React, { FunctionComponent } from "react";
import { i18n, SupportedLanguages } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { FormInput } from "../common/form/FormInput";
import { FormSelect, OptionProps, renderOption } from "../common/form/FormSelect";
import { StopNGoState, OnChangeConfiguration } from "./Configuration";
import { FormMediaUpload } from "../common/form/FormMediaUpload";
import { StopNGoShowroomConfigSlideshowMediaTypes } from "@technis/shared";

type Props = {
  configuration: StopNGoState;
  onChange: OnChangeConfiguration;
};

const languages: OptionProps[] = [
  { value: SupportedLanguages.FRENCH, title: "Français" },
  { value: SupportedLanguages.ENGLISH, title: "English" },
  { value: SupportedLanguages.POLISH, title: "Polski" },
];

export const ConfigurationGeneral: FunctionComponent<Props> = props => {
  const { configuration, onChange } = props;
  return (
    <div className="general two-col">
      <div className="inputs-spacing">
        <FormInput
          label={i18n.t(translation.common.limit)}
          value={configuration.limit}
          onChange={e => {
            onChange({ limit: e.target.value });
          }}
          placeholder={i18n.t(translation.edit.general.selectLimit)}
        />
        <FormSelect
          label={i18n.t(translation.common.language)}
          className="select"
          value={configuration.lang}
          onSelect={e => {
            onChange({ lang: e as string });
          }}
        >
          {languages.map(renderOption)}
        </FormSelect>
      </div>
      <FormMediaUpload
        waitForUploadLabel={i18n.t(translation.edit.general.uploadLogo)}
        style={{ height: "150px" }}
        value={
          configuration.logoToUpload
            ? { data: configuration.logoToUpload, type: StopNGoShowroomConfigSlideshowMediaTypes.IMAGE }
            : configuration.logoToUpload === undefined && configuration.logoUrl
            ? { data: configuration.logoUrl, type: StopNGoShowroomConfigSlideshowMediaTypes.IMAGE }
            : undefined
        }
        onChange={base64 => {
          onChange({ logoToUpload: base64 });
        }}
      />
    </div>
  );
};
