export const translation = {
  edit: {
    general: {
      selectLimit: "edit.general.selectLimit",
      uploadLogo: "edit.general.uploadLogo",
    },
    soundConfig: {
      selectTimeout: "edit.soundConfig.selectTimeout",
      overLimit: "edit.soundConfig.overLimit",
    },
    customization: {
      goSectionTitle: "edit.customization.goSectionTitle",
      stopSectionTitle: "edit.customization.stopSectionTitle",
      backgroundColor: "edit.customization.backgroundColor",
      gradientColor: "edit.customization.gradientColor",
    },
    showroomConfig: {
      externalMedias: "edit.showroomConfig.externalMedias",
      uploadMedia: "edit.showroomConfig.uploadMedia",
      urlClipboard: "edit.showroomConfig.urlClipboard",
      addSlideshow: "edit.showroomConfig.addSlideshow",
      addMedia: "edit.showroomConfig.addMedia",
      whatDoYouWantToShow: "edit.showroomConfig.whatDoYouWantToShow",
      useCustomStopNGo: "edit.showroomConfig.useCustomStopNGo",
      enterUrl: "edit.showroomConfig.enterUrl",
      promptDeleteMedia: "edit.showroomConfig.promptDeleteMedia",
    },
    invalidUrl: "edit.invalidUrl",
    openStopNGo: "edit.openStopNGo",
    openPreviousStopNGo: "edit.openPreviousStopNGo",
    saveSuccess: "edit.saveSuccess",
    saveError: "edit.saveError",
    unsupportedMediaFile: "edit.unsupportedMediaFile",
    tooltips: {
      tags: "edit.tooltips.tags",
    },
  },
  common: {
    french: "common.french",
    english: "common.english",
    polish: "common.polish",
    type: "common.type",
    beep: "common.beep",
    voice: "common.voice",
    general: "common.general",
    language: "common.language",
    limit: "common.limit",
    timeout: "common.timeout",
    sounds: "common.sounds",
    go: "common.go",
    stop: "common.stop",
    customization: "common.customization",
    title: "common.title",
    color: "common.color",
    message: "common.message",
    logo: "common.logo",
    icon: "common.icon",
    showroom: "common.showroom",
    slideshows: "common.slideshows",
    unit: "common.unit",
    minute: "common.minute",
    person: "common.person",
    percent: "common.percent",
    medias: "common.medias",
    url: "common.url",
    save: "common.save",
    saving: "common.saving",
    mediaLibrary: "common.mediaLibrary",
    from: "common.from",
    to: "common.to",
    myMediaLibrary: "common.myMediaLibrary",
    or: "common.or",
    ok: "common.ok",
    cancel: "common.cancel",
  },
  stop: {
    title: "stop.title",
    message: "stop.message",
  },
  go: {
    title: "go.title",
    message: "go.message",
    warning: "go.warning",
  },
  errors: {
    404: "errors.404",
    noServer: {
      title: "errors.noServer.title",
      subtitle: "errors.noServer.subtitle",
    },
    noInternet: {
      title: "errors.noInternet.title",
      subtitle: "errors.noInternet.subtitle",
    },
    noAccess: {
      title: "errors.noAccess.title",
      subtitle: "errors.noAccess.subtitle",
    },
    noEvent: {
      title: "errors.noEvent.title",
      subtitle: "errors.noEvent.subtitle",
    },
    noPeriod: {
      title: "errors.noPeriod.title",
      subtitle: "errors.noPeriod.subtitle",
    },
    noLimit: {
      title: "errors.noLimit.title",
      subtitle: "errors.noLimit.subtitle",
    },
    noToken: {
      title: "errors.noToken.title",
      subtitle: "errors.noToken.subtitle",
    },
  },
  waitingTime: {
    lessThanFiveMinutes: "waitingTime.lessThanFiveMinutes",
    fromToMinutes: "waitingTime.fromToMinutes",
    moreThanThirtyMinutes: "waitingTime.moreThanThirtyMinutes",
  },
};
