import React, { FunctionComponent } from "react";
import { IoIosSad } from "react-icons/io";
import { IconType } from "react-icons";

import { i18n } from "../../../lang/i18n";
import classNames from "classnames";

interface Props {
  title: string;
  subtitle?: string | React.ReactNode;
  Icon?: IconType;
  iconSize?: number;
  className?: string;
}

export const CommonError: FunctionComponent<Props> = ({ Icon = IoIosSad, iconSize = 90, title, subtitle, className = "" }) => (
  <div className={classNames("common-error", className)}>
    <Icon className="icon" size={iconSize} />
    <div className="text">
      <span className="title">{i18n.t(title)}</span>
      {subtitle ? <span className="subtitle">{typeof subtitle === "string" ? i18n.t(subtitle) : subtitle}</span> : null}
    </div>
  </div>
);
