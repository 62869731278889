import { ID, Maybe, TokenType } from "@technis/shared";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { eventById, EventByIdResult } from "../../graphql/event.gql";
import { periodByEvent, PeriodByEventResult } from "../../graphql/period.gql";
import { stopngoTokenData, StopNGoTokenDataResult } from "../../graphql/stopngoTokenData.gql";
import { zonesByInstallation, ZonesByInstallationResult } from "../../graphql/zone.gql";
import { setLanguage, SupportedLanguages } from "../../lang/i18n";
import { RootState } from "../../redux/store";
import { inEnum } from "../../utils/utils";
import { ErrorSelection, NoEventError, NoLimitError, NoPeriodError, NotAuthorizedError, NoTokenError } from "../common/error/Errors";
import { Loader } from "../common/svg/Loader";

import { StopNGo } from "./StopNGo";
import { ApolloError } from "apollo-client";
import { getAPITokenType, GetAPITokenTypeResult } from "../../graphql/stopngo.gql";
import { COOKIE_STOPNGO_TOKEN, useCookieToken } from "../../hooks/token";

const POLL_INTERVAL = 30000;
const FIRST_TS = Date.now();

export enum UserLanguage {
  FR = "fr",
  EN = "en",
  PL = "pl",
}

type StopNGoContainerProps = RouteComponentProps<{ eventId: string }> & { forceUrlToken?: boolean; isFuture?: boolean };

export const StopNGoContainer: FunctionComponent<StopNGoContainerProps> = props => {
  const {
    match: { params },
    forceUrlToken,
    location: { search },
  } = props;
  const eventId = Number(params.eventId) || undefined;
  const isSplitStopNGo = new URLSearchParams(search).get("split") === "true";
  const isMute = new URLSearchParams(search).get("mute") === "true";

  const cookieToken = useCookieToken(COOKIE_STOPNGO_TOKEN);
  const token = useSelector((state: RootState) => state.auth.token);
  const tokenTypeQuery = useQuery<GetAPITokenTypeResult>(getAPITokenType, {
    fetchPolicy: "network-only",
    skip: !token,
  });
  const tokenType = tokenTypeQuery.data?.getAPITokenType;

  let view: React.ReactNode = <ErrorSelection message={tokenTypeQuery.error?.message} DefaultError={NoTokenError} />;
  if (cookieToken || forceUrlToken) {
    if (token) {
      if (!tokenTypeQuery.loading) {
        if (tokenType === TokenType.STOPNGO || tokenType === TokenType.PRIVATE) {
          view = <StopNGoDataFetching token={token} tokenType={tokenType} eventId={eventId} isSplitStopNGo={isSplitStopNGo} isMute={isMute} />;
        }
      } else {
        view = null;
      }
    } else {
      view = null;
    }
  }

  return <div className="stopngo-container">{view}</div>;
};

type StopNGoDataFetchingProps = {
  token: string;
  tokenType: TokenType;
  eventId: number | undefined;
  isSplitStopNGo?: boolean;
  isMute?: boolean;
};

const StopNGoDataFetching: FunctionComponent<StopNGoDataFetchingProps> = props => {
  const { token, tokenType, eventId, isSplitStopNGo, isMute } = props;

  let data: {
    isTokenStopNGo: boolean;
    refetchData: () => void;
    event: EventByIdResult["eventById"];
    topZoneId: Maybe<ID>;
    periodId: Maybe<ID>;
    loading: boolean;
    error: Maybe<ApolloError>;
    flowRate?: number;
  };

  if (tokenType === TokenType.PRIVATE) {
    const eventQuery = useQuery<EventByIdResult>(eventById, {
      variables: { eventId },
      fetchPolicy: "network-only",
      skip: !token,
    });
    const event = eventQuery.data?.eventById;
    const periodQuery = useQuery<PeriodByEventResult>(periodByEvent, {
      variables: { eventId, date: FIRST_TS },
      fetchPolicy: "network-only",
      skip: !token,
    });
    const period = periodQuery.data?.periodByEvent;
    const zonesQuery = useQuery<ZonesByInstallationResult>(zonesByInstallation, {
      variables: { installationId: event?.installationId },
      fetchPolicy: "network-only",
      skip: !token || !event?.installationId,
    });
    const topZone = zonesQuery.data?.zonesByInstallation?.find(z => !z.parentIds.length);

    data = {
      isTokenStopNGo: false,
      event,
      periodId: period?.id,
      topZoneId: topZone?.id,
      refetchData: () => {
        eventQuery.refetch();
        periodQuery.refetch({ date: Date.now() });
        zonesQuery.refetch();
      },
      loading: eventQuery.loading || periodQuery.loading || zonesQuery.loading,
      error: eventQuery.error || periodQuery.error || zonesQuery.error,
      flowRate: topZone?.flowRate,
    };
  } else {
    const stopngoDataQuery = useQuery<StopNGoTokenDataResult>(stopngoTokenData, {
      variables: { eventId },
      fetchPolicy: "network-only",
      skip: !token,
    });
    const stopngoDatas = stopngoDataQuery.data?.stopngoTokenData;

    data = {
      isTokenStopNGo: true,
      event: stopngoDatas,
      periodId: stopngoDatas?.periodId,
      topZoneId: stopngoDatas?.topZoneId,
      flowRate: stopngoDatas?.topZone?.flowRate,
      refetchData: () => {
        stopngoDataQuery.refetch();
      },
      loading: stopngoDataQuery.loading,
      error: stopngoDataQuery.error,
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      data.refetchData();
    }, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    data.refetchData();
  }, [token]);

  const { limit, lang } = data.event?.stopngo || {};

  // Language
  const [usedLanguage, setUsedLanguageLanguage] = useState(SupportedLanguages.ENGLISH);
  useEffect(() => {
    if (inEnum(SupportedLanguages, lang)) {
      setLanguage(lang as SupportedLanguages);
      setUsedLanguageLanguage(lang as SupportedLanguages);
    }
  }, [lang]);

  // Loading timeout
  const [loading, setLoading] = useState(true);

  if (!data.isTokenStopNGo && data.error) return <ErrorSelection message={data.error.message} DefaultError={NotAuthorizedError} />;
  if (data.loading) return <Loader />;
  if (!data.event) return <ErrorSelection message={data.isTokenStopNGo ? data.error?.message : undefined} DefaultError={NoEventError} />;
  if (!data.periodId) return <ErrorSelection DefaultError={NoPeriodError} />;
  if (!data.topZoneId) return <ErrorSelection DefaultError={NotAuthorizedError} />;
  if (!data.event.stopngo || !limit) return <ErrorSelection DefaultError={NoLimitError} />;
  if (data.isTokenStopNGo && data.error) return <ErrorSelection message={data.error.message} DefaultError={NotAuthorizedError} />;

  return (
    <StopNGo
      limit={limit}
      periodId={data.periodId || 0}
      topZoneId={data.topZoneId}
      loading={loading}
      setLoading={setLoading}
      token={token}
      lang={usedLanguage}
      stopngo={data.event.stopngo}
      eventName={data.event.name}
      isSplitStopNGo={isSplitStopNGo}
      isMute={isMute}
      flowRate={data.flowRate}
    />
  );
};
