import React from "react";
import { Patterns } from "./Patterns";

export interface Parser {
  pattern: RegExp;
  render: (matched: string, key: number) => JSX.Element;
}

export const formItemTooltipParser: () => Parser = () => ({
  pattern: Patterns.richtext,
  render: (matched: string, key: number) => {
    const [styleTag, text] = matched.slice(1, -1).split("|");
    switch (styleTag) {
      case "title":
        return (
          <div key={key} className="tooltip-title">
            {text}
          </div>
        );
      case "tagTitle":
        return (
          <span key={key} className="tooltip-title tag">
            {text}
          </span>
        );
      case "info":
        return (
          <span key={key} className="tooltip-info">
            {text}
          </span>
        );
      case "tag":
        return (
          <span key={key} className="tooltip-info tag">
            {text}
          </span>
        );
      case "newLine":
        return <br key={key} />;
      default:
        return <div key={key}>{text}</div>;
    }
  },
});
