import React, { FunctionComponent } from "react";

import { FormErrorsProps } from "./FormErrors";
import { FormItem, LabelSharedProps } from "./FormItem";
import { TimePicker } from "antd";
import { TimePickerProps } from "antd/lib/time-picker";
import classNames from "classnames";

type Props = LabelSharedProps & FormErrorsProps & TimePickerProps;

export const FromTimePicker: FunctionComponent<Props> = (props: Props) => {
  const { label, className, itemClassName, labelNextToInput, errors, ...otherProps } = props;

  return (
    <FormItem label={label} itemClassName={itemClassName} errors={errors} labelNextToInput={labelNextToInput}>
      <TimePicker className={classNames("time-picker", className)} {...otherProps} />
    </FormItem>
  );
};
