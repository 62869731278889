import InputANTD, { InputProps } from "antd/lib/input/Input";
import React, { FunctionComponent } from "react";
import { Maybe } from "@technis/shared";

import { FormErrorsProps } from "./FormErrors";
import { FormItem, HelperSharedProps, LabelSharedProps } from "./FormItem";
import classNames from "classnames";

type Props = Omit<InputProps, "value"> &
  LabelSharedProps &
  HelperSharedProps &
  FormErrorsProps & {
    value?: Maybe<InputProps["value"]>;
  };

export enum InputTypes {
  EMAIL = "email",
  NUMBER = "number",
  PASSWORD = "password",
  TEXT = "text",
}

export const FormInput: FunctionComponent<Props> = (props: Props) => {
  const { label, className, itemClassName, labelNextToInput, errors, value, helperText, ...otherProps } = props;

  return (
    <FormItem label={label} itemClassName={itemClassName} errors={errors} labelNextToInput={labelNextToInput} helperText={helperText}>
      <InputANTD className={classNames("form-input", className)} {...otherProps} value={value as InputProps["value"]} />
    </FormItem>
  );
};
