import { useEffect } from "react";
import { SupportedLanguages } from "../lang/i18n";
import { getSounds, Sound } from "../sounds";

export enum SupportedSounds {
  BEEP = "beep",
}

export type SoundLanguages = SupportedLanguages | SupportedSounds;

const QUEUE_SIZE = 1;

export enum SoundModes {
  Instant,
  Queue,
}

class CustomSound {
  sound: Sound;
  audio: HTMLAudioElement;
  locale: SoundLanguages;
  onSoundEnd: () => void;
  timeoutDuration?: number;
  canPlay = true;

  constructor(src: Sound, locale?: SoundLanguages, onSoundEnd?: () => void, noRepeatTimeout?: number, isMute = false) {
    this.sound = src;
    this.locale = locale || SupportedLanguages.ENGLISH;
    this.audio = new Audio(getSounds(this.locale)[src]);
    if (isMute) this.audio.volume = 0;
    this.onSoundEnd = () => {
      if (this.isPlaying()) this.stop();
      onSoundEnd && onSoundEnd();
    };
    this.audio.addEventListener("ended", this.onSoundEnd);
    if (noRepeatTimeout) {
      this.timeoutDuration = noRepeatTimeout;
    }
  }

  enablePlay = () => {
    this.canPlay = true;
  };
  play = () => {
    if (this.canPlay) {
      this.audio.play();
      if (this.timeoutDuration) {
        this.canPlay = false;
        setTimeout(this.enablePlay, this.timeoutDuration);
      }
    }
  };
  pause = () => {
    this.audio.pause();
  };
  stop = () => {
    this.audio.pause();
    this.audio.currentTime = 0;
  };
  isPlaying = () => this.audio.currentTime > 0;
  isBlocked = () => !this.canPlay;
}

const queue: Sound[] = [];
let players: CustomSound[] = [];
export const useMultipleAudio = (sounds: Sound[], locale?: SoundLanguages, mode: SoundModes = SoundModes.Instant, noRepeatTimeout = 0, isMute?: boolean) => {
  const playNext = () => {
    if (!queue[0]) return;
    const player = players.find(p => p.sound === queue[0]);
    if (!player?.isBlocked()) {
      playInstantly(queue.shift() as Sound);
    } else if (player?.isPlaying() || (player?.isBlocked() && queue.length > 1)) {
      queue.shift();
      playNext();
    } else {
      setTimeout(playNext, 1000);
    }
  };

  useEffect(() => {
    players = sounds.map(sound => new CustomSound(sound, locale, playNext, noRepeatTimeout * 1000, isMute));
  }, [locale, sounds, noRepeatTimeout]);

  const addToQueue = (sound: Sound) => {
    if (!isPlaying() && !queue.length) {
      playInstantly(sound);
    } else if (queue.length >= QUEUE_SIZE) {
      queue[queue.length - 1] = sound;
    } else {
      queue.push(sound);
    }
  };

  const playInstantly = (sound: Sound) => {
    players.forEach(player => {
      if (sound === player.sound && !player.isPlaying()) return player.play();
      if (sound !== player.sound && player.isPlaying()) {
        player.stop();
      }
    });
  };

  const play = (sound: Sound) => {
    switch (mode) {
      case SoundModes.Instant:
        return playInstantly(sound);
      case SoundModes.Queue:
        return addToQueue(sound);
    }
  };

  const pause = (sound?: Sound) => (sound ? players.find(player => sound === player.sound)?.pause() : players.forEach(player => player.pause()));
  const stop = (sound?: Sound) => (sound ? players.find(player => sound === player.sound)?.stop() : players.forEach(player => player.stop()));
  const isPlaying = () => !!players.find(player => player.isPlaying());

  return { play, stop, pause };
};
