import TextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import React, { FunctionComponent } from "react";

import { FormErrorsProps } from "./FormErrors";
import { FormItem, HelperSharedProps, LabelSharedProps } from "./FormItem";
import classNames from "classnames";

type Props = TextAreaProps & LabelSharedProps & FormErrorsProps & HelperSharedProps;

export const FormTextArea: FunctionComponent<Props> = (props: Props) => {
  const { label, className, itemClassName, labelNextToInput, errors, helperText, ...otherProps } = props;

  return (
    <FormItem helperText={helperText} label={label} itemClassName={itemClassName} errors={errors} labelNextToInput={labelNextToInput}>
      <TextArea className={classNames("form-input", className)} {...otherProps} />
    </FormItem>
  );
};
