/* eslint-disable max-len */
import React from "react";

interface Props {
  color: string;
  className?: string;
}

export const StopHand = (props: Props) => {
  const { color, className } = props;
  return (
    <svg fill={color} className={className} height="456pt" viewBox="-65 0 455 456" width="456pt">
      <path d="m76.320312 456h98.015626c27.351562 0 52.660156-15.882812 66.046874-41.601562l84.785157-162.835938-4.785157-3.917969c-.089843-.078125-.179687-.15625-.265624-.234375-7.367188-6.929687-17.28125-10.488281-27.371094-9.816406-10.304688.785156-19.820313 5.820312-26.269532 13.898438l-24.457031 29.773437c-1.875 2.28125-4.984375 3.140625-7.765625 2.144531-2.785156-.996094-4.640625-3.628906-4.644531-6.585937l-.140625-204.832031c-.011719-13.960938-10.410156-25.316407-23.183594-25.316407-12.492187 0-22.699218 10.828125-23.171875 24.34375 0 20.6875-.011719 43.207031-.015625 64.640625-.007812 48.476563-.015625 90.316406 0 94.375.007813 1.851563-.726562 3.628906-2.035156 4.9375-1.3125 1.304688-3.089844 2.035156-4.941406 2.027344h-.023438c-1.847656.015625-3.621094-.707031-4.9375-2.003906-1.3125-1.296875-2.054687-3.066406-2.0625-4.910156-.015625-4.085938-.007812-45.902344 0-94.402344 0-20.921875.007813-42.890625.011719-63.171875-.011719-.164063-.019531-.316407-.019531-.480469 0-.398438.007812-.78125.019531-1.179688 0-17.027343 0-32.753906 0-45.628906.007813-13.957031-10.394531-25.222656-23.175781-25.222656h-.710938c-12.78125 0-23.386718 11.175781-23.386718 25.144531v204.972657c0 3.863281-3.136719 7-7 7-3.867188 0-7-3.136719-7-7v-178.472657c0-13.96875-10.183594-25.332031-22.964844-25.332031-12.855469 0-23.035156 11.363281-23.035156 25.332031v178.472657c0 3.863281-3.136719 7-7 7-3.867188 0-7-3.136719-7-7v-105.203126c0-13.972656-10.628907-24.914062-23.414063-24.914062h-.667969c-12.75 0-23.152344 11.074219-23.183594 25-.132812 57.488281-.050781 158.84375.035157 249.570312.039062 44.953126 34.007812 81.429688 75.714843 81.429688zm0 0" />
    </svg>
  );
};
