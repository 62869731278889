import React, { FunctionComponent } from "react";
import { StopNGoState } from "./Configuration";
import classNames from "classnames";
// eslint-disable-next-line import/no-unresolved
import { ClassValue } from "classnames/types";

type Props = {
  configuration: StopNGoState;
  className?: ClassValue;
};

export const ConfigurationPreview: FunctionComponent<Props> = props => {
  const { className } = props;
  return <div className={classNames("preview", className)}>PREVIEW</div>;
};
