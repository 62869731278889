export const TRACKING_DEBOUNCE_TIME = 500;
export const TrackingViewActivityTopic = {
  home: "Home",
  stopNGoConfiguration: "Stop&Go Configuration",
  forceUrlTokenStopnGo: "Force Url Token StopNGo",
  stopNGoTokenInCookieRedirect: "Stop N Go Token In Cookie Redirect",
  slideshow: "Slideshow",
};

export const TrackingViewActivityName = {
  error: "Error",
  stopNGoConfiguration: "Stop&Go Configuration",
  forceUrlTokenStopnGo: "Stop&Go Forced Url",
  stopNGoTokenInCookieRedirect: "Stop&Go Cookie Redirection",
  slideshow: "Viewed slideshow",
};
