export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_5_MINUTES = 5 * SECONDS_IN_MINUTE;
export const SECONDS_IN_10_MINUTES = 10 * SECONDS_IN_MINUTE;
export const SECONDS_IN_15_MINUTES = 15 * SECONDS_IN_MINUTE;
export const SECONDS_IN_20_MINUTES = 20 * SECONDS_IN_MINUTE;
export const SECONDS_IN_30_MINUTES = 30 * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTES = 60;
export const MINUTES_IN_HOURS = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;

export const MONTHS_IN_YEAR = 12;

export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * MINUTES_IN_HOURS;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * HOURS_IN_DAY;
export const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * DAYS_IN_WEEK;
export const MILLISECONDS_IN_YEAR = MILLISECONDS_IN_DAY * DAYS_IN_YEAR;

export const MINUTES_IN_DAY = MINUTES_IN_HOURS * HOURS_IN_DAY;
export const MINUTES_IN_WEEK = MINUTES_IN_DAY * DAYS_IN_WEEK;
export const MINUTES_IN_YEAR = MINUTES_IN_DAY * DAYS_IN_YEAR;

export const SECONDS_IN_YEAR = MINUTES_IN_YEAR * SECONDS_IN_MINUTES;

export const DAYS_IN_WORKING_WEEK = 5;

export const MODAL_DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm";
