import { ID } from "@technis/shared";
import { ApolloQueryResult, MutationOptions, OperationVariables, QueryOptions, SubscriptionOptions } from "apollo-client";
import { FetchResult } from "apollo-link";
import { DocumentNode } from "graphql";
import { StopNGoCreateInput, StopNGoUpdateInput } from "../../types/stopngo.mutations";

import { apollo } from "../api/apollo";
import { removeUndefined } from "../utils/utils";

import { renewToken, RenewTokenResult } from "./auth.gql";
import { eventById, EventByIdResult } from "./event.gql";
import { periodByEvent, PeriodByEventResult } from "./period.gql";
import { createStopNGoConfiguration, CreateStopNGoResult, updateStopNGoConfiguration, UpdateStopNGoResult, GetAPITokenTypeResult, getAPITokenType } from "./stopngo.gql";
import { zonesByInstallation, ZonesByInstallationResult } from "./zone.gql";
import { StopNGoTokenCreateInput } from "../../types/stopngoToken.mutations";
import { CreateStopNGoTokenResult, createStopNGoToken, GetStopNGoAPITokenResult, getStopNGoAPIToken } from "./stopngoTokenData.gql";
import { queryTranslationDetails, QueryTranslationDetailsResult, QueryTranslationResult, queryTranslations } from "./translation.gql";

const client = {
  refresh: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables" | "fetchPolicy">) =>
    apollo.client.query({
      query,
      variables: removeUndefined(variables),
      ...(options || {}),
      fetchPolicy: "network-only",
    }),
  query: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables">) =>
    apollo.client.query({ query, variables: removeUndefined(variables), ...(options || {}) }),
  mutate: (mutation: DocumentNode, variables: OperationVariables = {}, options?: Omit<MutationOptions<OperationVariables>, "variables">) =>
    apollo.client.mutate({ mutation, variables: removeUndefined(variables), ...(options || {}) }),
  subscribe: <T>(query: DocumentNode, variables: OperationVariables = {}, options?: Omit<SubscriptionOptions<OperationVariables>, "variables">) =>
    apollo.client.subscribe({ query, variables: removeUndefined(variables), ...(options || {}) }),
};

type Query<T> = ApolloQueryResult<T>;
type Mutation<T> = FetchResult<Partial<T>>;

export namespace gql {
  export const Auth = {
    renew: (): Promise<Query<RenewTokenResult>> => client.query(renewToken),
  };
  export const Event = {
    get: (eventId: ID): Promise<Query<EventByIdResult>> => client.query(eventById, { eventId }),
  };
  export const StopNGo = {
    create: (stopngo: StopNGoCreateInput): Promise<Mutation<CreateStopNGoResult>> => client.mutate(createStopNGoConfiguration, { stopngo }),
    update: (stopngo: StopNGoUpdateInput): Promise<Mutation<UpdateStopNGoResult>> => client.mutate(updateStopNGoConfiguration, { stopngo }),
  };
  export const StopNGoToken = {
    create: (token: StopNGoTokenCreateInput): Promise<Mutation<CreateStopNGoTokenResult>> => client.mutate(createStopNGoToken, { token }),
    getAPIToken: (tokenId: string): Promise<Query<GetStopNGoAPITokenResult>> => client.query(getStopNGoAPIToken, { tokenId }),
  };
  export const Period = {
    byEvent: (eventId: ID, date: number): Promise<Query<PeriodByEventResult>> =>
      client.query(periodByEvent, {
        eventId,
        date,
      }),
  };
  export const User = {
    getAPITokenType: (): Promise<Query<GetAPITokenTypeResult>> => client.query(getAPITokenType),
  };
  export const Zone = {
    byInstallation: (installationId: ID): Promise<Query<ZonesByInstallationResult>> => client.query(zonesByInstallation, { installationId }),
  };
  export const Translation = {
    translationDetails: (): Promise<Query<QueryTranslationDetailsResult>> => client.query(queryTranslationDetails),
    translations: (): Promise<Query<QueryTranslationResult>> => client.query(queryTranslations),
  };
}
