import { StopNGoCustomText, StopNGoShowroomConfigSlideshowMediaTypes } from "@technis/shared";
import React, { FunctionComponent } from "react";
import { StopNGoCustomTextCreateInput, StopNGoCustomTextUpdateInput } from "../../../types/stopngo.mutations";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { FormColorInput } from "../common/form/FormColorInput";
import { FormInput } from "../common/form/FormInput";
import { FormMediaUpload } from "../common/form/FormMediaUpload";
import { FormTextArea } from "../common/form/FormTextArea";
import { OnChangeConfiguration, StopNGoState } from "./Configuration";
import { ConfigurationSectionTitle } from "./ConfigurationSectionTitle";

type Props = {
  configuration: StopNGoState;
  onChange: OnChangeConfiguration;
};

type CustomizationProps = Pick<Props, "onChange"> & {
  sectionTitle: string;
  pathPrefix: string;
  customizationState: StopNGoCustomText & StopNGoCustomTextCreateInput & StopNGoCustomTextUpdateInput;
};

const Customization: FunctionComponent<CustomizationProps> = props => {
  const { sectionTitle, pathPrefix, customizationState, onChange } = props;
  const {
    iconToUpload,
    iconUrl,
    title,
    message,
    styles: { titleColor, messageColor, backgroundColors },
  } = customizationState;
  const isGradientColorDisabled = !backgroundColors[0];
  return (
    <div className="customization">
      <ConfigurationSectionTitle title={sectionTitle} />
      <div className="single-customization two-col">
        <FormMediaUpload
          label={i18n.t(translation.common.logo)}
          labelNextToInput={true}
          value={
            iconToUpload
              ? { data: iconToUpload, type: StopNGoShowroomConfigSlideshowMediaTypes.IMAGE }
              : iconToUpload === undefined && iconUrl
              ? { data: iconUrl, type: StopNGoShowroomConfigSlideshowMediaTypes.IMAGE }
              : undefined
          }
          onChange={base64 => {
            onChange({ [`${pathPrefix}.iconToUpload`]: base64 });
          }}
        />
        <div className="inputs">
          <div className="input-with-color">
            <FormInput
              label={i18n.t(translation.common.title)}
              value={title}
              onChange={e => {
                onChange({ [`${pathPrefix}.title`]: e.target.value });
              }}
              placeholder={i18n.t(translation.common.title)}
            />
            <FormColorInput label={i18n.t(translation.common.color)} value={titleColor} onChange={hex => onChange({ [`${pathPrefix}.styles.titleColor`]: hex })} />
          </div>
          <div className="input-with-color">
            <FormTextArea
              label={i18n.t(translation.common.message)}
              helperText={i18n.t(translation.edit.tooltips.tags)}
              value={message}
              onChange={e => {
                onChange({ [`${pathPrefix}.message`]: e.target.value });
              }}
              placeholder={i18n.t(translation.common.message)}
              rows={3}
            />
            <FormColorInput
              label={i18n.t(translation.common.color)}
              value={messageColor}
              onChange={hex => {
                onChange({ [`${pathPrefix}.styles.messageColor`]: hex });
              }}
            />
          </div>
          <div className="inputs-bg-colors">
            <FormColorInput
              label={i18n.t(translation.edit.customization.backgroundColor)}
              value={backgroundColors[0]}
              onChange={hex => {
                if (!hex) {
                  onChange({ [`${pathPrefix}.styles.backgroundColors`]: [] });
                  return;
                }
                const gradientColor = backgroundColors[1];
                onChange({ [`${pathPrefix}.styles.backgroundColors`]: [hex, ...(gradientColor ? [gradientColor] : [])] });
              }}
            />
            <FormColorInput
              disabled={isGradientColorDisabled}
              label={i18n.t(translation.edit.customization.gradientColor)}
              value={backgroundColors[1]}
              onChange={hex => {
                onChange(hex ? { [`${pathPrefix}.styles.backgroundColors.1`]: hex } : { [`${pathPrefix}.styles.backgroundColors`]: [backgroundColors[0]] });
              }}
              style={isGradientColorDisabled ? { opacity: 0.5 } : undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConfigurationCustomization: FunctionComponent<Props> = props => {
  const { configuration, onChange } = props;
  return (
    <>
      <Customization
        sectionTitle={i18n.t(translation.edit.customization.goSectionTitle)}
        pathPrefix="customTexts.go"
        customizationState={configuration.customTexts.go}
        onChange={onChange}
      />
      <Customization
        sectionTitle={i18n.t(translation.edit.customization.stopSectionTitle)}
        pathPrefix="customTexts.stop"
        customizationState={configuration.customTexts.stop}
        onChange={onChange}
      />
    </>
  );
};
