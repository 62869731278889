import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import {
  Maybe,
  StopNGoShowroomConfigSlideshow,
  StopNGoShowroomConfigSlideshowMediaTypes,
  StopNGoShowroomConfigSlideshowResource,
  StopNGoShowroomConfigSlideshowUnit,
} from "@technis/shared";
import { MediaWithBlur } from "../common/Media";
import {
  DEFAULT_SLIDESHOW_IMAGE_DURATION,
  findResourceInUsableMedias,
  getUsableMediaInfos,
  STOPNGO_SLIDESHOW_KEY,
  UpdateSlideshows,
  UsableMedia,
} from "./ConfigurationShowroomConfig";
import { IoIosAdd, IoIosClose, IoIosVideocam } from "react-icons/io";
import { FormInput } from "../common/form/FormInput";
import classNames from "classnames";
import { StopWatch } from "../common/svg/StopWatch";
import { Modal, ModalProps } from "../common/Modal";
import { ConfigurationSectionTitle } from "./ConfigurationSectionTitle";
import { SquareDiv } from "../common/SquareDiv";
import { translation } from "../../lang/translation";
import { i18n } from "../../lang/i18n";
import { FromTimePicker } from "../common/form/FromTimePicker";
import moment from "moment";
import { GradientIcon } from "../common/GradientIcon";
import { getThemeColors } from "../../utils/colors";
import { ThemeContext } from "../common/Theme";
import { toHoursAndMinutes } from "../../utils/utils";

type MediaSelectionModalProps = ModalProps &
  Pick<StopNGoConfigurationShowroomConfigSlideshowProps, "usableMedias" | "toggleMediaLibrary"> & {
    onClick: (url: string, type: Maybe<StopNGoShowroomConfigSlideshowMediaTypes>, userMediaKey?: Maybe<string>) => void;
  };

const MediaSelectionModal: FunctionComponent<MediaSelectionModalProps> = ({ visible, onClose, usableMedias, onClick, toggleMediaLibrary }) => (
  <Modal visible={visible} onClose={onClose} contentClassName="media-selection-modal">
    <ConfigurationSectionTitle title={i18n.t(translation.edit.showroomConfig.whatDoYouWantToShow)} />
    <div className="media-list">
      <div
        className="use-stopngo"
        onClick={() => {
          onClick(STOPNGO_SLIDESHOW_KEY, StopNGoShowroomConfigSlideshowMediaTypes.IMAGE);
        }}
      >
        {i18n.t(translation.edit.showroomConfig.useCustomStopNGo)}
      </div>
      <span className="separator">{i18n.t(translation.common.or)}</span>
      <div className="medias">
        {usableMedias.map((m, i) => {
          const { url, userMediaKey, type } = getUsableMediaInfos(m);
          return (
            <SquareDiv key={`${m}-${i}`} className="media-option">
              <MediaWithBlur src={url} type={type} onClick={() => onClick(url, type, userMediaKey)} />
            </SquareDiv>
          );
        })}
        <SquareDiv
          className={classNames("media-option", "add-new-media")}
          onClick={() => {
            toggleMediaLibrary(true);
          }}
        >
          <span>+</span>
          <span>Add media</span>
        </SquareDiv>
      </div>
    </div>
  </Modal>
);

type ResourceProps = Pick<StopNGoConfigurationShowroomConfigSlideshowProps, "usableMedias" | "updateSlideshows" | "toggleMediaLibrary"> & {
  resource: StopNGoShowroomConfigSlideshowResource;
  isLast: boolean;
  slideshowIndex: number;
  resourceIndex: number;
  onDelete: (index: number) => void;
};

const Resource: FunctionComponent<ResourceProps> = ({ resource, usableMedias, updateSlideshows, slideshowIndex, resourceIndex, onDelete, toggleMediaLibrary }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [theme] = useContext(ThemeContext);
  const media = findResourceInUsableMedias(usableMedias, resource);
  if (!media) return null;
  const { url, type } = getUsableMediaInfos(media);
  const isVideo = type === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO;
  return (
    <>
      <div className="resource">
        <div className="selection">
          <div className={classNames("duration", { video: !!isVideo })}>
            <div className="icon">
              {<GradientIcon iconComponent={isVideo ? <IoIosVideocam /> : <StopWatch className="stop-watch" />} gradientColors={getThemeColors(theme).gradients} />}
            </div>
            <FormInput
              className="hide-box-shadow"
              value={resource.duration}
              onChange={e => {
                const v = e.target.value;
                const num = Number(v);
                if (isNaN(num) && v !== "") return;
                updateSlideshows({ resourcesToUpdate: { [slideshowIndex]: { [resourceIndex]: { duration: v === "" ? null : num } } } });
              }}
            />
          </div>
          <MediaWithBlur src={url} type={type} width="auto" onClick={() => setIsModalVisible(true)} />
          <div
            className="delete-button"
            onClick={() => {
              onDelete(resourceIndex);
            }}
          >
            <IoIosClose />
          </div>
        </div>
      </div>
      <MediaSelectionModal
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        usableMedias={usableMedias}
        onClick={(selectedMediaUrl, selectedMediaType, selectedMediaUserKey) => {
          updateSlideshows({
            resourcesToUpdate: {
              [slideshowIndex]: {
                [resourceIndex]: {
                  mediaKey: selectedMediaUserKey || selectedMediaUrl,
                  duration: selectedMediaType === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO ? null : resource.duration || DEFAULT_SLIDESHOW_IMAGE_DURATION,
                },
              },
            },
          });
          setIsModalVisible(false);
        }}
        toggleMediaLibrary={toggleMediaLibrary}
      />
    </>
  );
};

type UntilValueProps = Pick<StopNGoConfigurationShowroomConfigSlideshowProps, "unit"> & {
  value: Maybe<number | string>;
  onValueChange?: (v: Maybe<number | string>) => void;
  className?: string;
  isLast?: boolean;
};

const MOMENT_FORMAT = "HH:mm";

const UntilValue: FunctionComponent<UntilValueProps> = props => {
  const { value, onValueChange, className, unit, isLast } = props;
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    setNewValue(value);
  }, [props]);
  const textStart = onValueChange ? translation.common.to : translation.common.from;
  const view = (() => {
    if (!onValueChange || (isLast && unit !== StopNGoShowroomConfigSlideshowUnit.MINUTE)) return <span className="value">{newValue}</span>;
    if (unit === StopNGoShowroomConfigSlideshowUnit.MINUTE) {
      if (isLast && typeof newValue === "number") return <span className="value">{toHoursAndMinutes(newValue)}</span>;
      const m = moment();
      m.set({ h: 0, minute: 0 });
      m.add(newValue || 0, "minutes");
      return (
        <FromTimePicker
          itemClassName="value"
          value={m}
          defaultValue={moment("00:00", MOMENT_FORMAT)}
          format={MOMENT_FORMAT}
          onChange={v => {
            setNewValue(v ? v.hours() * 60 + v.minutes() : 0);
          }}
          onOpenChange={open => (!open ? onValueChange(newValue) : null)}
          allowClear
        />
      );
    }
    const onLeaveField = () => onValueChange(newValue);
    return (
      <FormInput className="hide-box-shadow" itemClassName="value" value={newValue} onChange={e => setNewValue(e.target.value)} onPressEnter={onLeaveField} onBlur={onLeaveField} />
    );
  })();
  const textEnd = (u => {
    if (u === StopNGoShowroomConfigSlideshowUnit.MINUTE) {
      if (!onValueChange) {
        return "h";
      }
      return null;
    }
    if (u === StopNGoShowroomConfigSlideshowUnit.PERSON) return i18n.t(translation.common.person, { count: value }).toLowerCase();
    return "%";
  })(unit);
  return (
    <div className={classNames("until-container", className, { "person-unit": unit === StopNGoShowroomConfigSlideshowUnit.PERSON })}>
      {i18n.t(textStart, { count: value }).toLowerCase()}
      {view}
      {textEnd}
    </div>
  );
};

type StopNGoConfigurationShowroomConfigSlideshowProps = {
  slideshow: StopNGoShowroomConfigSlideshow;
  index: number;
  usableMedias: UsableMedia[];
  updateSlideshows: (u: UpdateSlideshows) => void;
  unit: StopNGoShowroomConfigSlideshowUnit;
  toggleMediaLibrary: (v: boolean) => void;
  isLast?: boolean;
};

export const ConfigurationShowroomConfigSlideshow: FunctionComponent<StopNGoConfigurationShowroomConfigSlideshowProps> = ({
  slideshow,
  index,
  usableMedias,
  updateSlideshows,
  unit,
  isLast,
  toggleMediaLibrary,
}) => {
  const { resources, until } = slideshow;
  const [isAddNewMediaModalVisible, setIsAddNewMediaModalVisible] = useState(false);
  return (
    <>
      <div className="slideshow">
        {index === 0 ? <UntilValue value={0} className="top" unit={unit} /> : null}
        <div className="resources">
          {resources.map((r, i) => (
            <Resource
              key={`${r.mediaKey}-${i}`}
              resource={r}
              isLast={resources.length - 1 === i}
              usableMedias={usableMedias}
              updateSlideshows={updateSlideshows}
              slideshowIndex={index}
              resourceIndex={i}
              onDelete={resourceIndex => {
                updateSlideshows({ resourcesToDelete: { [index]: { [resourceIndex]: true } } });
              }}
              toggleMediaLibrary={toggleMediaLibrary}
            />
          ))}
        </div>
        <div
          className="add-media-button"
          onClick={() => {
            setIsAddNewMediaModalVisible(true);
          }}
        >
          <IoIosAdd />
        </div>
        {until ? (
          <UntilValue
            isLast={isLast}
            value={until}
            onValueChange={v => {
              const num = Number(v);
              if (isNaN(num) && v !== "") return;
              updateSlideshows({ slideshowsToUpdate: { [index]: { until: num } } });
            }}
            className={classNames("bottom", { last: !!isLast })}
            unit={unit}
          />
        ) : null}
      </div>
      {until ? (
        <MediaSelectionModal
          visible={isAddNewMediaModalVisible}
          onClose={() => {
            setIsAddNewMediaModalVisible(false);
          }}
          usableMedias={usableMedias}
          onClick={(selectedMediaUrl, selectedMediaType, selectedMediaUserKey) => {
            updateSlideshows({
              resourcesToAdd: {
                [index]: {
                  [resources.length]: {
                    mediaKey: selectedMediaUserKey || selectedMediaUrl,
                    duration: selectedMediaType === StopNGoShowroomConfigSlideshowMediaTypes.VIDEO ? null : DEFAULT_SLIDESHOW_IMAGE_DURATION,
                  },
                },
              },
            });
            setIsAddNewMediaModalVisible(false);
          }}
          toggleMediaLibrary={toggleMediaLibrary}
        />
      ) : null}
    </>
  );
};
