import InputANTD, { InputProps } from "antd/lib/input/Input";
import React, { FunctionComponent, useState } from "react";
import { ChromePicker } from "react-color";

import { FormErrorsProps } from "./FormErrors";
import { FormItem, LabelSharedProps } from "./FormItem";
import { OutClicker } from "../OutClicker";
import { IoIosCloseCircle } from "react-icons/io";
import { Maybe } from "@technis/shared";
import classNames from "classnames";

type Props = Omit<InputProps, "onChange" | "onClick"> &
  LabelSharedProps &
  FormErrorsProps & { inputClassName?: string; pickerClassName?: string; onChange: (hex: Maybe<string>) => void; onClick?: () => void };

export const FormColorInput: FunctionComponent<Props> = (props: Props) => {
  const { onClick, label, inputClassName, itemClassName, pickerClassName, labelNextToInput, errors, value, onChange, disabled, style, ...otherProps } = props;
  const [visible, setVisible] = useState(false);

  return (
    <FormItem label={label} itemClassName={itemClassName} errors={errors} labelNextToInput={labelNextToInput} style={style}>
      <OutClicker onClick={() => setVisible(false)}>
        <div className="color-picker-container">
          <InputANTD
            onClick={() => {
              onClick && onClick();
              setVisible(!visible);
            }}
            className={classNames("form-input", "color-picker-field", inputClassName, {
              disabled: disabled,
              "has-value": !!value,
            })}
            value={value}
            disabled={disabled}
            {...otherProps}
          />
          {!disabled && value ? <IoIosCloseCircle onClick={() => onChange(null)} className="close-button" /> : null}
        </div>
        {visible ? (
          <ChromePicker
            className={classNames("color-picker", pickerClassName)}
            color={String(value) || "#fff"}
            onChange={(value: { hex: string }) => {
              onChange && onChange(value.hex);
            }}
            disableAlpha={true}
          />
        ) : null}
      </OutClicker>
    </FormItem>
  );
};
