import React, { FunctionComponent } from "react";
import { Switch } from "antd";

type Props = {
  title: string;
  enabled?: boolean;
  onToggle?: (v: boolean) => void;
};

export const ConfigurationSectionTitle: FunctionComponent<Props> = ({ title, enabled, onToggle, children }) => (
  <div className="section-title">
    <span className="title">{title}</span>
    {onToggle ? <Switch checked={enabled} onClick={onToggle} className="switch" /> : null}
    {children}
  </div>
);
