import { message } from "antd";
import React from "react";
import { IconType } from "react-icons";
import { IoIosCheckmarkCircle, IoIosInformationCircle, IoIosWarning } from "react-icons/io";

type Props = {
  icon?: IconType;
  text: string;
};

type Status = "success" | "error" | "info";

const getIcon = (type: Status, CustomIcon?: IconType) => {
  let Icon = null;
  let color = null;
  switch (type) {
    case "success":
      Icon = IoIosCheckmarkCircle;
      color = "green";
      break;
    case "info":
      Icon = IoIosInformationCircle;
      color = "orange";
      break;
    default:
      Icon = IoIosWarning;
      color = "red";
  }
  if (CustomIcon) {
    Icon = CustomIcon;
  }
  return <Icon style={{ color }} />;
};

// TODO SET MATERIAL DESIGN SNACKS
export const Message = {
  success: (props: Props) =>
    message.open({
      content: props.text,
      icon: getIcon("success", props.icon),
      type: "success",
      duration: 3,
    }),
  error: (props: Props) =>
    message.open({
      content: props.text,
      icon: getIcon("error", props.icon),
      type: "error",
      duration: 3,
    }),
  info: (props: Props) =>
    message.open({
      content: props.text,
      icon: getIcon("info", props.icon),
      type: "info",
      duration: 3,
    }),
};
