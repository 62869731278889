// eslint-disable-next-line import/named
import { AnalyticsBrowser, UserTraits } from "@segment/analytics-next";
import { TrackingAction, UserID } from "@technis/shared";

const projectId = process.env.SEGMENT_PROJECT;

export namespace SegmentTracking {
  let segment: AnalyticsBrowser;
  export const init = async () => {
    if (projectId) {
      segment = AnalyticsBrowser.load({ writeKey: projectId });
    }
  };

  export const saveAction = <T extends Omit<TrackingAction, "id" | "projectId" | "receivedAt" | "sessionId" | "category">>(payload: T) => {
    const { name, ...rest } = payload;
    if (projectId && Boolean(segment)) {
      segment.track(payload.name, rest);
    }
  };

  export const setUser = <T extends UserTraits>(userId: UserID, traits?: T) => {
    if (projectId && Boolean(segment)) {
      segment.identify(userId, traits);
    }
  };

  export const trackPageActivity = (type: string, name: string, url?: string) => {
    if (projectId && Boolean(segment)) {
      segment.page(type, name, { url });
    }
  };
}
