import React, { FunctionComponent } from "react";
import { IoIosAlert, IoIosPulse, IoIosSad } from "react-icons/io";
import { MdSignalWifiOff } from "react-icons/md";
import { AuthErrors } from "@technis/shared";

import { i18n } from "../../../lang/i18n";
import { CommonError } from "./CommonError";
import { translation } from "../../../lang/translation";
import { TrackingViewActivityTopic, TrackingViewActivityName } from "../../../utils/constants";

const inEnumReversed = (enu: object, value: string) => Object.values(enu).find(e => value.includes(e));
const NETWORK_ERROR_SUBSTRING = "Failed to fetch";

export const ErrorSelection = ({ message, errorClassName, DefaultError }: { message?: string; errorClassName?: string; DefaultError: React.ComponentType<{}> }) => {
  if (!window.navigator.onLine) {
    return <NoNetworkError className={errorClassName} />;
  }
  if (message) {
    if (message.includes(NETWORK_ERROR_SUBSTRING)) {
      return <NoServerError className={errorClassName} />;
    }
    if (inEnumReversed(AuthErrors, message)) {
      return <NotAuthorizedError className={errorClassName} />;
    }
  }
  return <DefaultError />;
};

export const NoNetworkError: FunctionComponent<{ className?: string }> = ({ className }) => (
  <CommonError
    Icon={MdSignalWifiOff}
    iconSize={90}
    title={translation.errors.noInternet.title}
    subtitle={<span>{i18n.t(translation.errors.noInternet.subtitle)}</span>}
    className={className}
  />
);

export const NotAuthorizedError: FunctionComponent<{ className?: string }> = ({ className }) => (
  <CommonError
    Icon={IoIosAlert}
    iconSize={90}
    title={translation.errors.noAccess.title}
    subtitle={<span>{i18n.t(translation.errors.noAccess.subtitle)}</span>}
    className={className}
  />
);

export const NoEventError = () => (
  <CommonError Icon={IoIosAlert} iconSize={90} title={translation.errors.noEvent.title} subtitle={<span>{i18n.t(translation.errors.noEvent.subtitle)}</span>} />
);

export const NoPeriodError = () => (
  <CommonError Icon={IoIosAlert} iconSize={90} title={translation.errors.noPeriod.title} subtitle={<span>{i18n.t(translation.errors.noPeriod.subtitle)}</span>} />
);

export const NoLimitError = () => (
  <CommonError Icon={IoIosAlert} iconSize={90} title={translation.errors.noLimit.title} subtitle={<span>{i18n.t(translation.errors.noLimit.subtitle)}</span>} />
);

export const NoTokenError = () => (
  <CommonError Icon={IoIosSad} iconSize={90} title={translation.errors.noToken.title} subtitle={<span>{i18n.t(translation.errors.noToken.subtitle)}</span>} />
);

export const NoServerError: FunctionComponent<{ className?: string }> = ({ className }) => (
  <CommonError
    Icon={IoIosPulse}
    iconSize={80}
    title={translation.errors.noServer.title}
    subtitle={translation.errors.noServer.subtitle}
    className={className !== undefined ? className : "error-fullscreen"}
  />
);
