import { useEffect, useRef, EffectCallback } from "react";

export const useInterval = (callback: EffectCallback, delay: number, initCallback?: EffectCallback) => {
  const savedCallback = useRef<EffectCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay) {
      if (initCallback) {
        initCallback();
      }
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
};
