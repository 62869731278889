import { Translation } from "@technis/shared";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { Action, Dispatch } from "redux";
import { RehydrateAction } from "redux-persist";

import { gql } from "../../graphql";
import { RootState } from "../store";

export enum AppActions {
  TRANSLATION_DETAILS = "TRANSLATION_DETAILS",
  TRANSLATIONS = "TRANSLATIONS",
}

interface TranslationDetailsAction extends Action<AppActions.TRANSLATION_DETAILS> {
  details: Translation;
}
interface TranslationsAction extends Action<AppActions.TRANSLATIONS> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  translations: object;
}

export type AppAction = TranslationDetailsAction | TranslationsAction | RehydrateAction;

export const getTranslations = async (dispatch: Dispatch<AppAction>) => {
  const result = await gql.Translation.translations();

  dispatch({
    type: AppActions.TRANSLATIONS,
    translations: result.data.translationsByProject,
  });
};

export const getTranslationDetails = () => async (dispatch: Dispatch<AppAction>, getState: () => RootState) => {
  const result = await gql.Translation.translationDetails();
  const { details } = getState().app;

  if (
    !isEqual(get(details, "version"), get(result, "data.translationDetailsByProject.version")) ||
    !isEqual(get(details, "allowedLanguages"), get(result, "data.translationDetailsByProject.allowedLanguages"))
  ) {
    getTranslations(dispatch);
  }

  dispatch({
    type: AppActions.TRANSLATION_DETAILS,
    details: result.data.translationDetailsByProject,
  });
};
