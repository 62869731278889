import { StopNGoShowroomConfigSlideshowMediaTypes, Maybe } from "@technis/shared";
import { inEnum } from "./utils";

const SUPPORTED_IMAGE_FORMATS = ["jpeg", "jpg", "png", "gif"];
const SUPPORTED_VIDEO_FORMATS = ["mp4", "webm", "mov", "m4v"];
const VALID_EXTERNAL_MEDIA_URL_REGEXS = [
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//,
  /(?:soundcloud\.com|snd\.sc)\/[^.]+$/,
  /vimeo\.com\/.+/,
  /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/,
  /streamable\.com\/([a-z0-9]+)$/,
  /(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(.*)$/,
  /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/,
  /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/,
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/,
  /mixcloud\.com\/([^/]+\/[^/]+)/,
  /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-]+)/,
];

export const getBase64 = (file: File | Blob | undefined): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const getBase64MediaType = (encoded: Maybe<string>): StopNGoShowroomConfigSlideshowMediaTypes | null => {
  let result = null;
  if (typeof encoded !== "string") {
    return result;
  }
  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (mime && mime.length) {
    result = mime[1].split("/")[0];
  }
  return inEnum(StopNGoShowroomConfigSlideshowMediaTypes, result) ? (result as StopNGoShowroomConfigSlideshowMediaTypes) : null;
};

export const checkMediaUrl = (url: string, extensions: string[]) => !!url.match(`^(https?)://.*(${extensions.join("|")})`);

export const getExternalMediaType = (url: string) => {
  if (!url) return null;
  return checkMediaUrl(url, SUPPORTED_IMAGE_FORMATS)
    ? StopNGoShowroomConfigSlideshowMediaTypes.IMAGE
    : !!VALID_EXTERNAL_MEDIA_URL_REGEXS.find(regx => !!url.match(regx)) || checkMediaUrl(url, SUPPORTED_VIDEO_FORMATS)
    ? StopNGoShowroomConfigSlideshowMediaTypes.VIDEO
    : null;
};
