import React, { FunctionComponent, useState } from "react";
import { StopNGoState, OnChangeConfiguration } from "./Configuration";
import { Modal } from "../common/Modal";
import { ConfigurationSectionTitle } from "./ConfigurationSectionTitle";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { FormMediaUpload } from "../common/form/FormMediaUpload";
import { createUID } from "../../utils/utils";
import { isMediaNewFile, UsableMedia, isMediaExternalMedia, UpdateSlideshows, getUsableMediaInfos } from "./ConfigurationShowroomConfig";
import { getBase64MediaType, getExternalMediaType } from "../../utils/image";
import { FormInput } from "../common/form/FormInput";
import { MediaWithBlur } from "../common/Media";
import { IoIosTrash, IoIosOpen } from "react-icons/io";
import { Popconfirm } from "antd";
import { Colors } from "../../utils/colors";

type MediaItemProps = {
  media: UsableMedia;
  onDeleteNewMedia: (base64: string) => void;
  onDeleteMedia: (url: string) => void;
  onDeleteExternalMedia: (url: string) => void;
};

const MediaItem: FunctionComponent<MediaItemProps> = ({ media, onDeleteNewMedia, onDeleteMedia, onDeleteExternalMedia }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { url, type } = getUsableMediaInfos(media);
  const onDelete = isMediaNewFile(media) ? onDeleteNewMedia : isMediaExternalMedia(media) ? onDeleteExternalMedia : onDeleteMedia;
  const documentId = `mediaLibrary-${url}`;
  const doc = document.getElementById(documentId);
  return (
    <div id={documentId} className="media-item">
      <MediaWithBlur src={url} type={type} />
      <div className="media-infos">
        <a href={url} rel="noopener noreferrer" target="_blank">
          <IoIosOpen />
        </a>
        <Popconfirm
          visible={isPopoverVisible}
          onConfirm={() => onDelete(url)}
          onCancel={() => setIsPopoverVisible(false)}
          title={i18n.t(translation.edit.showroomConfig.promptDeleteMedia)}
          icon={<IoIosTrash color={Colors.RED} />}
          okText={i18n.t(translation.common.ok).toUpperCase()}
          okType="danger"
          cancelText={i18n.t(translation.common.cancel)}
          getPopupContainer={doc ? () => doc : undefined}
        >
          <div onClick={() => setIsPopoverVisible(true)}>
            <IoIosTrash />
          </div>
        </Popconfirm>
      </div>
    </div>
  );
};

type Props = {
  configuration: StopNGoState;
  usableMedias: UsableMedia[];
  onChange: OnChangeConfiguration;
  visible: boolean;
  onClose: () => void;
  updateSlideshows: (u: UpdateSlideshows) => void;
};

export const ConfigurationMediaLibraryModal: FunctionComponent<Props> = props => {
  const { configuration, usableMedias, visible, onClose, onChange, updateSlideshows } = props;
  const { mediasToAdd, mediasToDelete, externalMediasToAdd, externalMediasToDelete, mediaLibrary } = configuration.showroomConfig;

  /***
   * Add
   */

  const saveNewMedia = (base64: string) => {
    if (getBase64MediaType(base64) && !mediasToAdd?.find(m => m.data === base64)) {
      onChange({
        [`showroomConfig.mediasToAdd.${mediasToAdd?.length || 0}`]: {
          data: base64,
          userMediaKey: createUID(),
        },
      });
    }
  };

  const [newExternalMediaUrl, setNewExternalMediaUrl] = useState("");
  const saveNewExternalMedia = () => {
    if (getExternalMediaType(newExternalMediaUrl)) {
      setNewExternalMediaUrl("");
      if (externalMediasToAdd?.find(m => m === newExternalMediaUrl)) {
        return;
      }
      const toDeleteDiff = externalMediasToDelete?.filter(m => m !== newExternalMediaUrl);
      if (toDeleteDiff?.length !== externalMediasToDelete?.length) {
        onChange({ ["showroomConfig.externalMediasToDelete"]: toDeleteDiff });
      } else {
        onChange({ [`showroomConfig.externalMediasToAdd.${externalMediasToAdd?.length || 0}`]: newExternalMediaUrl });
      }
    }
  };

  /***
   * Delete
   */

  const deleteMedia = (url: string) => {
    updateSlideshows({ _mediasToDelete: [...(mediasToDelete || []), url] });
  };

  const deleteNewMedia = (base64: string) => {
    const toAddDiff = mediasToAdd?.filter(m => m.data !== base64);
    if (toAddDiff?.length === mediasToAdd?.length) return;
    updateSlideshows({ _mediasToAdd: toAddDiff });
  };

  const deleteExternalMedia = (url: string) => {
    const mediaLibraryDiff = mediaLibrary.filter(m => m.url !== url);
    if (mediaLibraryDiff.length !== mediaLibrary.length) {
      updateSlideshows({ _externalMediasToDelete: [...(externalMediasToDelete || []), url] });
    } else {
      const toAddDiff = externalMediasToAdd?.filter(u => u !== url);
      if (toAddDiff?.length !== externalMediasToAdd?.length) {
        updateSlideshows({ _externalMediasToAdd: toAddDiff });
      }
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} contentClassName="media-library">
      <ConfigurationSectionTitle title={i18n.t(translation.common.mediaLibrary)} />
      <div className="new-media-upload">
        <FormMediaUpload
          waitForUploadLabel={i18n.t(translation.edit.showroomConfig.uploadMedia)}
          style={{ height: "100px" }}
          onChange={base64 => {
            return base64 ? saveNewMedia(base64) : null;
          }}
        />
        <span className="or-separator">{i18n.t(translation.common.or)}</span>
        <FormInput
          value={newExternalMediaUrl}
          onChange={e => {
            setNewExternalMediaUrl(e.target.value);
          }}
          onPressEnter={saveNewExternalMedia}
          placeholder={i18n.t(translation.edit.showroomConfig.enterUrl)}
        />
      </div>
      <div className="media-list">
        {usableMedias.map(media => (
          <MediaItem
            key={isMediaNewFile(media) ? media.data : isMediaExternalMedia(media) ? media : media.url}
            media={media}
            onDeleteMedia={deleteMedia}
            onDeleteNewMedia={deleteNewMedia}
            onDeleteExternalMedia={deleteExternalMedia}
          />
        ))}
      </div>
    </Modal>
  );
};
