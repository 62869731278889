import { SoundLanguages } from "../hooks/audio";
import { en } from "./en";
import { fr } from "./fr";
import { pl } from "./pl";
import { beep } from "./beep";

type Indexed<T extends object, U> = {
  [K in keyof T]: U;
};

export type Sound = keyof typeof en;

export const getSounds = (locale?: SoundLanguages): Indexed<typeof en, string> => {
  switch (locale) {
    case "beep":
      return beep;
    case "fr":
      return fr;
    case "pl":
      return pl;
    case "en":
    default:
      return en;
  }
};
