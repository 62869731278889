import React, { FunctionComponent, useEffect } from "react";

import { Maybe } from "@technis/shared";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { OutClicker } from "./OutClicker";
import { IoIosClose } from "react-icons/io";

let numOfModalsOpened = 0;

export type ModalProps = {
  containerClassName?: string;
  contentClassName?: string;
  visible: Maybe<boolean>;
  onClose: () => void;
};

export const Modal: FunctionComponent<ModalProps> = ({ containerClassName, contentClassName, visible, children, onClose }) => {
  useEffect(() => {
    if (visible) {
      numOfModalsOpened++;
      document.body.className = "no-scroll";
    } else if (numOfModalsOpened > 0) {
      numOfModalsOpened--;
      if (!numOfModalsOpened) {
        document.body.className = "";
      }
    }
  }, [visible]);

  const modal = (
    <div className={classNames("modal-container", visible ? "show" : "hide", containerClassName)}>
      <OutClicker containerClassName={classNames("modal-content", contentClassName)} onClick={onClose} stopClickPropagation={true}>
        {children}
      </OutClicker>
      <div className="modal-close" onClick={onClose}>
        <IoIosClose />
      </div>
    </div>
  );

  const domElement = document.getElementById("root");
  return domElement && visible ? ReactDOM.createPortal(modal, domElement) : null;
};
